import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import ModalCategoryAdd from '../component/ModalCategoryAdd';
import Loader from '../component/Loader';

const VideoCategory = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isProgramModalOpen, setIsProgramModalOpen] = useState(false);
  const [arrCate, setArrCate] = useState<null | []>([]);
  const isMobile = MyUtil.isMobile;


  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
  }, []);




  const m_mng_mv_category_query = useQuery([arrCate], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_mv_category({}); }, {
    retry: 1, enabled: arrCate === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_category', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_category', result);
      if (isOk) {
        setArrCate(data.array);
      } else {
        setArrCate(null);
        MyUtil._alertMsg('m_mng_mv_category', data);
      };
    },
  });


  const { mutate: m_mng_mv_category_i } = useMutation(ServerApi.m_mng_mv_category_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_category_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_category_i', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 등록이 완료되었습니다!" });
        setArrCate([]);  // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_mv_category_i', data);
      };
    }
  });


  const { mutate: m_mng_mv_category_d } = useMutation(ServerApi.m_mng_mv_category_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_category_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_category_d', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 삭제가 완료되었습니다!" });
        setArrCate([]);  // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_mv_category_d', data);
      };
    }
  });



  const ModalCb = useCallback((isOk, jData) => {
    setIsProgramModalOpen(false);
    if (isOk) {
      m_mng_mv_category_i(jData);
    };
  }, []);





  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
              <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              <div className="titleBtn-Con" onClick={() => { setIsProgramModalOpen(true); }}>
                <span className='titleBtn-text'>카테고리 추가</span>
              </div>
            </div>

            <div className="container-wrap">
              {
                !MyUtil._isNull(arrCate) && arrCate?.map((item: any, idx: number) => (
                  <div key={idx} className={isMobile ? 'itembox03 b-width-100' : 'itembox03 b-width-25'}>

                    <span className="itembox03-title01">{item.mv_category_nm}</span>
                    <span className="itembox03-title02">동영상 {item.mv_ct}개</span>

                    <div className='itembox03-bottomBtnWrap'>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '8px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }} onClick={() => {
                        const cnt = parseInt(item.mv_ct);
                        if (cnt !== 0) {
                          return Modal.warning({ content: '먼저 카테고리안에 있는 영상을 모두 삭제해주세요.' });
                        };

                        Modal.confirm({
                          okText: "확인", cancelText: "취소",
                          title: '카테고리 삭제',
                          content: '카테고리를 삭제할까요?',
                          onOk() { m_mng_mv_category_d({ mv_category_no: item.mv_category_no }) },
                          onCancel() { },
                        });
                      }} >{'삭제'}</span>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '0px', color: '#0fb500', borderColor: '#0fb500', backgroundColor: '#ffffff' }} onClick={() => { history.push({ pathname: '/CategoryVideoAdd', state: { korName: item.mv_category_nm, mv_category_no: item.mv_category_no } }); }}>{'영상 관리'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }



      {
        isProgramModalOpen && (
          <ModalCategoryAdd ModalCb={ModalCb} isModalOpen={isProgramModalOpen} />
        )
      }
    </div>
  )
};

export default VideoCategory;