import { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import * as MyUtil from "../constants/MyUtil";
import * as SG from '../constants/Signature';
import * as ServerApi from "../constants/ServerApi";
import '../css/CssOverride.css';
const isMobile = MyUtil.isMobile;


const ModalPackageSelect = ({ ModalCb, isModalOpen, loginInfo }: SG.ModalProps) => {
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '550px';
  const [loading, setLoading] = useState<boolean>(false);
  const [arrProgram, setArrProgram] = useState<null | []>([]);


  const m_mng_pack_query = useQuery([arrProgram], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_pack({ center_no: loginInfo.center_no }); }, {
    retry: 1, enabled: arrProgram === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pack', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pack', result);

      if (isOk) {
        setArrProgram(data.array);
      } else {
        setArrProgram(null);
        MyUtil._alertMsg('m_mng_pack', data);
      };
    },
  });





  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title="패키지 추가"
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>

        <div className="container-wrap" style={{ paddingLeft: '0px', paddingRight: '0px', maxHeight: '300px', marginTop: '10px', overflowY: 'auto' }}>
          {
            !MyUtil._isNull(arrProgram) && arrProgram?.map((item: any, idx) => (
              <div key={idx} className={'itemBoxModal'} style={{ backgroundColor: '#f2bb3d', position: 'relative', overflow: 'hidden' }} onClick={() => {
                ModalCb(true, item);
              }}>
                <span className="itembox05-title02" style={{}}>{item.pack_nm}</span>
              </div>
            ))
          }
        </div>
      </div>
    </Modal>
  );
}
export default ModalPackageSelect;