import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import 'antd/dist/antd.css';
import NavTopBar from './NavTopBar';
import NavSide from './NavSide';
import Colors from '../constants/Colors';
// ------ 관리자용 ------ //
import Login from "../screens-head/Login";
import BranchManage from "../screens-head/BranchManage";
import ProgramsItem from "../screens-head/ProgramsItem";
import TutorialVideo from "../screens-head/TutorialVideo";
import Sales from "../screens-head/Sales";
import PackagesItem from "../screens-head/PackagesItem";
import BranchProgramsCalendar from "../screens-head/BranchProgramsCalendar";
import BarabomTest from "../screens-head/BarabomTest";
import BarabomTestList from "../screens-head/BarabomTestList";
import ProgramsVideo from "../screens-head/ProgramsVideo";
import PackagesProgram from "../screens-head/PackagesProgram";
import VideoCategory from "../screens-head/VideoCategory";
// ------ 지점용 ------ //
import SBLogin from "../screens-branch/SBLogin";
import SBDayProgram from "../screens-branch/SBDayProgram";
import SBVideoPlay from "../screens-branch/SBVideoPlay";
import SABPayList from "../screens-branch/SABPayList";


const RouterComponent = ({ }) => {

    return (
        <Router>
            <Route render={({ location, history }: any) => {
                // console.log('location : ' + JSON.stringify(location))

                return (
                    <React.Fragment>
                        <div>
                            <NavSide location={location} />

                            <div className="appContainer">
                                <NavTopBar location={location} />

                                <div className="contentsBox">
                                    <main style={{backgroundColor:Colors.default_BgColor}}>
                                        <Route path="/" exact component={(props: any) => <Login />}></Route>
                                        <Route path="/Login" exact component={(props: any) => <Login />}></Route>
                                        <Route path="/BranchManage" exact component={(props: any) => <BranchManage location={location} />}></Route>
                                        <Route path="/ProgramsItem" exact component={(props: any) => <ProgramsItem location={location} />}></Route>
                                        <Route path="/TutorialVideo" exact component={(props: any) => <TutorialVideo location={location} />}></Route>
                                        <Route path="/CategoryVideoAdd" exact component={(props: any) => <TutorialVideo location={location} />}></Route>
                                        <Route path="/PackagesItem" exact component={(props: any) => <PackagesItem location={location} />}></Route>
                                        <Route path="/Sales" exact component={(props: any) => <Sales location={location} />}></Route>
                                        <Route path="/BarabomTest" exact component={(props: any) => <BarabomTest location={location} />}></Route>
                                        <Route path="/BarabomTestList" exact component={(props: any) => <BarabomTestList location={location} />}></Route>
                                        <Route path="/ProgramsVideo" exact component={(props: any) => <ProgramsVideo location={location} />}></Route>
                                        <Route path="/PackagesProgram" exact component={(props: any) => <PackagesProgram location={location} />}></Route>
                                        <Route path="/VideoCategory" exact component={(props: any) => <VideoCategory location={location} />}></Route>
                                        <Route path="/BranchProgramsCalendar" exact component={(props: any) => <BranchProgramsCalendar location={location} />}></Route>
                                        
                                        <Route path="/SBLogin" exact component={(props: any) => <SBLogin location={location} />}></Route>
                                        <Route path="/SBDayProgram" exact component={(props: any) => <SBDayProgram location={location} />}></Route>
                                        <Route path="/SBVideoPlay" exact component={(props: any) => <SBVideoPlay location={location} />}></Route>
                                        <Route path="/SABPayList" exact component={(props: any) => <SABPayList location={location} />}></Route>
                                        {/* <Redirect from="*" to="/login"></Redirect>  <<< 넣으면 새로고침하면 처음으로 감. */}
                                    </main>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            }}
            />
        </Router>
    )
}

export default RouterComponent;