import * as MyUtil from "./MyUtil";

// https://velog.io/@jkl1545/React-Query
// https://velog.io/@chltjdrhd777/React-Query-%EC%BA%90%EC%8B%B1%EC%97%90-%EB%8C%80%ED%95%9C-%EA%B5%AC%ED%98%84

// 요약하면 R ( Read ) 은 useQuery , CUD ( Create, Update, Delete )는 useMutation을 사용하면 된다.
// 캐시가 있는 모든 쿼리 무효화 :  queryClient.invalidateQueries();
// staleTime : 동일 파라미터 요청 금지 시간

export function m_mng_center_check(data: { center_cd: string, center_pw: string }) {
    const REQ_METHODS = "m_mng_center_check";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_login(data: { center_cd: string }) {
    const REQ_METHODS = "m_mng_center_login";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_login(data: { userid: string, password: string }) {
    const REQ_METHODS = "m_mng_login";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_pack_d(data: {
    center_pack_no: string
}) {
    const REQ_METHODS = "m_mng_center_pack_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_config_i(data: {
    center_no:string,
    file_nm: string,
    config_type: string

}) {
    const REQ_METHODS = "m_mng_config_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_pack_i(data: {
    pack_no: string,
    c_day: string,
    center_no: any
}) {
    const REQ_METHODS = "m_mng_center_pack_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_i(data: {
    center_nm: string,
    center_pw: string,
    p_nm: string,
    p_tel: string,
    p_email: string,
    cost: string,
    cost_day: string,
    begin_date: string,
    center_cd: string,
    pay_type: string
}) {
    const REQ_METHODS = "m_mng_center_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_i(data: {
    pro_m_nm: string,
    pro_no: string,
    array: any,
    center_no: string
}) {
    const REQ_METHODS = "m_mng_pro_m_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};
export function m_mng_pack_pro_i(data: {
    pack_nm: string,
    pack_no: string,
    array: any,
    center_no: string
}) {
    const REQ_METHODS = "m_mng_pack_pro_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_center_u(data: {
    center_no: string,
    center_nm: string,
    center_pw: string,
    p_nm: string,
    p_tel: string,
    p_email: string,
    cost: string,
    cost_day: string,
    begin_date: string,
    center_cd: string,
    pay_type: string
}) {
    const REQ_METHODS = "m_mng_center_u";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_pack(data: { center_no: string, mon: string }) {
    const REQ_METHODS = "m_mng_center_pack";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_center(data: { center_nm: string }) {
    const REQ_METHODS = "m_mng_center";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_center_sales(data: { center_no: string, mon: string }) {
    const REQ_METHODS = "m_mng_center_sales";
    return MyUtil._httpReq(REQ_METHODS, data);
};
export function m_center_pro_m(data: { center_no: string, c_day: string }) {
    const REQ_METHODS = "m_center_pro_m";
    return MyUtil._httpReq(REQ_METHODS, data);
};
export function m_mng_pro_m(data: { center_no: string, c_day: string }) {
    const REQ_METHODS = "m_mng_pro_m";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack(data: {}) {
    const REQ_METHODS = "m_mng_pack";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv(data: { mv_category_no: string }) {
    const REQ_METHODS = "m_mng_mv";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_mv_category(data: {}) {
    const REQ_METHODS = "m_mng_mv_category";
    return MyUtil._httpReq(REQ_METHODS, data);
};


export function m_mng_mv_category_i(data: { mv_category_nm: string }) {
    const REQ_METHODS = "m_mng_mv_category_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu(data: {}) {
    const REQ_METHODS = "m_mng_tu";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv_category_d(data: {
    mv_category_no: string
}) {
    const REQ_METHODS = "m_mng_mv_category_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};
export function m_mng_mv_i(data: {
    mv_category_no: string,
    mv_no: string,
    mv_nm: string,
    mv_rep_img: string,
    mv_url: string,
    mv_url2: string,
    mv_url3: string
}) {
    const REQ_METHODS = "m_mng_mv_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu_i(data: {
    tu_no: string,
    tu_nm: string,
    tu_rep_img: string,
    tu_url: string
}) {
    const REQ_METHODS = "m_mng_tu_i";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_mv_d(data: {
    mv_no: string
}) {
    const REQ_METHODS = "m_mng_mv_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_tu_d(data: {
    tu_no: string
}) {
    const REQ_METHODS = "m_mng_tu_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pro_m_d(data: {
    pro_no: string
}) {
    const REQ_METHODS = "m_mng_pro_m_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};

export function m_mng_pack_d(data: {
    pack_no: string
}) {
    const REQ_METHODS = "m_mng_pack_d";
    return MyUtil._httpReq(REQ_METHODS, data);
};
export function m_mng_pack_pro_dt(data: {
    pack_no: string
}) {
    const REQ_METHODS = "m_mng_pack_pro_dt";
    return MyUtil._httpLegacyReq(REQ_METHODS, data);
};
