import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import Loader from '../component/Loader';

const SBDayProgramTutorial = ({ location }: any) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [arrVideItem, setArrVideItem] = useState<null | []>([]);


  useEffect(() => {
  }, []);


  const m_mng_tu = useQuery([arrVideItem], (ctx: QueryFunctionContext) => {
    return ServerApi.m_mng_tu({});
  }, {
    retry: 1, enabled: arrVideItem === null ? false : true,  // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_tu', error); },
    onSuccess: (result: any) => {
      setLoading(false); if (MyUtil._isNull(result)) { return; };
      const { isOk, data } = MyUtil._isResultSucces('m_mng_tu', result);

      if (isOk) {
        setArrVideItem(data.array);
      } else {
        setArrVideItem(null);
        MyUtil._alertMsg('m_mng_tu', data);
      };
    },
  });




  return (
    <div className="container-zero" style={{ backgroundColor: '#000000' }}>
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginTop: '35px' }}>
              {
                arrVideItem?.map((item: any, idx) => (
                  <div key={idx} className={'itembox03 b-width-25'} style={{ backgroundColor: '#000000', position: 'relative', overflow: 'hidden', borderColor: '#000000' }}>
                    <img style={{ width: '100%', height: '160px', objectFit: 'cover', borderRadius: '5px' }} src={item.tu_rep_img} alt="logo image" onClick={() => { history.push({ pathname: '/SBVideoPlay', state: { isTutorial: true, arrItem: [], tutoUrl: item.tu_url } }) }} />

                    <div style={{ width: '100%', height: '40px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.40)', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                      <span style={{ fontSize: '0.85em', color: '#ffffff', flex: 1, paddingLeft: '10px' }}>{item.tu_nm}</span>
                    </div>
                  </div>
                ))
              }
            </div>

          </>
        )
      }

    </div>
  )

};

export default SBDayProgramTutorial;