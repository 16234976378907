import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AWS from 'aws-sdk';
import Calendar from 'react-calendar';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import 'react-calendar/dist/Calendar.css'; // css import  https://velog.io/@khy226/%EB%A6%AC%EC%95%A1%ED%8A%B8-%EC%95%B1%EC%97%90-%EB%8B%AC%EB%A0%A5react-calendar-%EC%A0%81%EC%9A%A9%ED%95%98%EA%B8%B0
import moment from 'moment';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import ModalPackageSelect from '../component/ModalPackageSelect';
import '../css/Calendar.css';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import Config from '../constants/Config';
import ModalVideoIU from '../component/ModalVideoIU';
import Loader from '../component/Loader';

AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_AWSAccessKeyId}`,
  secretAccessKey: `${process.env.REACT_APP_AWSSecretKey}`
});

const myBucket = new AWS.S3({
  params: { Bucket: Config.S3_BUCKET },
  region: Config.S3_REGION,
});


const BranchProgramsCalendar = ({ location }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const branchInfo = MyUtil._isNull(location?.state?.branchInfo) ? '' : location?.state?.branchInfo;
  const [isProgramModalOpen, setIsProgramModalOpen] = useState(false);
  const [selectPackgeNo, setSelectPackgeNo] = useState('');
  const [selectDate, setSelectDate] = useState(new Date());
  const [selectMonth, setSelectMonth] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 1));
  const [arrMonPackeges, setArrMonPackeges] = useState<null | []>([]);
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const isMobile = MyUtil.isMobile;

  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
    setLoading(false);
  }, []);


  const m_mng_center_pack = useQuery([arrMonPackeges, selectMonth], (ctx: QueryFunctionContext) => {
    const formatDate = moment(selectMonth).format("YYYY-MM");
    return ServerApi.m_mng_center_pack({ center_no: branchInfo.center_no, mon: formatDate });
  }, {
    retry: 1, enabled: arrMonPackeges === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_pack', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_pack', result);

      if (isOk) {
        let getPackNo = '';

        data.array?.find((finDate: any) => {
          if (finDate.c_day === moment(selectDate).format("YYYY-MM-DD")) {
            getPackNo = finDate.center_pack_no;
            return
          };
        });

        setSelectPackgeNo(getPackNo + "");
        setArrMonPackeges(data.array);
      } else {
        setArrMonPackeges(null);
        MyUtil._alertMsg('m_mng_center_pack', data);
      };
    },
  });


  const { mutate: m_mng_center_pack_i } = useMutation(ServerApi.m_mng_center_pack_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_pack_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_pack_i', result);

      if (isOk) {
        Modal.success({ content: '정상적으로 등록이 완료되었습니다!' });
        // ---- 리스트 재조회 
        setArrMonPackeges([]);
      } else {
        MyUtil._alertMsg('m_mng_center_pack_i', data);
      };
    }
  });


  const { mutate: m_mng_center_pack_d } = useMutation(ServerApi.m_mng_center_pack_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_pack_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_pack_d', result);

      if (isOk) {
        Modal.success({ content: '정상적으로 삭제되었습니다!' });
        setArrMonPackeges([]);   // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_center_pack_d', data);
      };
    }
  });



  const { mutate: m_mng_config_i } = useMutation(ServerApi.m_mng_config_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_config_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_config_i', result);

      if (isOk) {
        Modal.success({ content: '정상적으로 등록이 완료되었습니다!' });
        if (!MyUtil._isNull(data.file_nm)) {
          DeleteS3object(data.file_nm);
        };
      } else {
        MyUtil._alertMsg('m_mng_config_i', data);
      };
    }
  });

  const DeleteS3object = useCallback((getVideoUrl) => {
    setLoading(true);

    const arrVideoUrl = getVideoUrl.split("/");
    const videoName = arrVideoUrl[arrVideoUrl.length - 2] + '/' + arrVideoUrl[arrVideoUrl.length - 1]
    const videoParams = { Bucket: Config.S3_BUCKET, Key: videoName };

    myBucket.deleteObject(videoParams)
      .on('success', (evt) => {

      })
      .send((err) => {
        if (err) Modal.warning({ content: 'DeleteS3object ERROR 22 : ' + err });
        setLoading(false);
      })

  }, []);


  const ModalCb = useCallback((isOk, jData) => {
    setIsProgramModalOpen(false);

    if (isOk) {
      let formatDate = selectDate.getFullYear() + "-" + (selectDate.getMonth() + 1) + "-" + selectDate.getDate();
      formatDate = moment(formatDate).format("YYYY-MM-DD");
      m_mng_center_pack_i({ pack_no: jData.pack_no, c_day: formatDate, center_no: branchInfo.center_no });
    }
  }, [selectDate, branchInfo]);


  const ModalVideoCb = useCallback((isOk, jData) => {
    setIsVideoIuModalOpen(false);

    if (isOk) {
      setTimeout(() => {
        setLoading(true);
        const nowDate = new Date();

        const videoName = 'bg/' + nowDate.getTime() + '_' + jData.videoFile.name;
        const videoParams = { Body: jData.videoFile, Bucket: Config.S3_BUCKET, Key: videoName };

        myBucket.putObject(videoParams)
          .on('success', (evt: any) => {
            m_mng_config_i({ center_no: branchInfo.center_no, config_type: 'c1', file_nm: process.env.REACT_APP_AWS_S3_URL + videoName });
          })
          .send((err) => {
            if (err) Modal.warning({ content: 'DeleteS3object ERROR 11 : ' + err });
            setLoading(false);
          })
      }, 300);
    };
  }, []);




  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{'지점 프로그램 등록'}</span>
              <span className="container-sub">{!MyUtil._isNull(location?.state?.branchInfo?.center_nm) ? location.state.branchInfo?.center_nm : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              {/* <div className="titleBtn-Con" onClick={() => { setIsVideoIuModalOpen(true); }}>
                <span className='titleBtn-text'>배경음악 등록</span>
              </div> */}

              {
                MyUtil._isNull(selectPackgeNo) ? (
                  <div className="titleBtn-Con" onClick={() => { setIsProgramModalOpen(true); }}>
                    <span className='titleBtn-text'>패키지 추가</span>
                  </div>
                ) : (
                  <div className="titleBtn-Con-del" onClick={() => {
                    Modal.confirm({
                      okText: "확인", cancelText: "취소",
                      title: '패키지 삭제',
                      content: '등록된 패키지를 삭제할까요?',
                      onOk() { m_mng_center_pack_d({ center_pack_no: selectPackgeNo }); },
                      onCancel() { },
                    });
                  }}>
                    <span className='titleBtn-text'>패키지 삭제</span>
                  </div>
                )
              }
            </div>



            <div className="container-wrap">
              <Calendar
                onActiveStartDateChange={(getDate) => {
                  const tranDate = getDate.activeStartDate;
                  setSelectMonth(tranDate.getFullYear() + "-" + (tranDate.getMonth() + 1));
                }}
                onChange={(value: any, event: any) => {
                  let getPackNo = '';

                  arrMonPackeges?.find((finDate: any) => {
                    if (finDate.c_day === moment(value).format("YYYY-MM-DD")) {
                      getPackNo = finDate.center_pack_no;
                      return
                    };
                  });
                  setSelectDate(value);
                  setSelectPackgeNo(getPackNo + "");
                }}
                formatDay={(locale, date) => moment(date).format("DD")} // 날'일' 제외하고 숫자만 보이도록 설정
                value={selectDate}
                minDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
                maxDetail="month" // 상단 네비게이션에서 '월' 단위만 보이게 설정
                // navigationLabel={null}
                showNeighboringMonth={false} //  이전, 이후 달의 날짜는 보이지 않도록 설정
                // className="mx-auto w-full text-sm border-b"
                // calendarType='Hebrew'
                tileContent={({ date, view }) => { // 날짜 타일에 컨텐츠 추가하기 (html 태그)
                  let html: any = [];
                  arrMonPackeges?.find((finDate: any) => {
                    if (finDate.c_day === moment(date).format("YYYY-MM-DD")) {
                      html.push(<div className="dot">{finDate.pack_nm}</div>);
                    };
                  });


                  // 다른 조건을 주어서 html.push 에 추가적인 html 태그를 적용할 수 있음.
                  return (
                    <div className="calitem">
                      {html}
                    </div>
                  );
                }}
              />
            </div>
          </>
        )
      }

      {
        isProgramModalOpen && (
          <ModalPackageSelect ModalCb={ModalCb} isModalOpen={isProgramModalOpen} loginInfo={rxLoginInfo} />
        )
      }

      {
        isVideoIuModalOpen && (
          <ModalVideoIU ModalCb={ModalVideoCb} isModalOpen={isVideoIuModalOpen} isBg={true} />
        )
      }

    </div>
  )

};

export default BranchProgramsCalendar;
