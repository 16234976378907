import { useState } from 'react';
import { Modal } from 'antd';
import * as MyUtil from "../constants/MyUtil";
import '../css/CssOverride.css';



const ModalCategoryAdd = ({ ModalCb, isModalOpen, getTitle = '영상 카테고리 추가' }: any) => {
  const isMobile = (window.innerWidth < 570);
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '400px';
  const [branchName, setBranchName] = useState('');



  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title={getTitle}
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>
        <div className="modalIpWrap" style={{ marginTop: '0px' }}>
          <span className="modalIpTitle">제 목</span>
          <input type="text" className="modalIp" required placeholder="입력해주세요"
            value={branchName} onChange={(evt) => setBranchName(evt.target.value)}></input>
        </div>

        <div className="modalSaveBtn" onClick={() => {
          if (MyUtil._isNull(branchName)) { return Modal.warning({ content: '패키지 이름을 입력해주세요.' }); };
          ModalCb(true, { mv_category_nm: branchName });
        }}>
          <span className="loginBtnTxt">저 장</span>
        </div>
      </div>
    </Modal>
  );
}
export default ModalCategoryAdd;