import { useState } from 'react';
import { Modal } from 'antd';
import * as SG from '../constants/Signature';
import * as MyUtil from '../constants/MyUtil';
import '../css/CssOverride.css';
import BannerItem2 from './BannerItem';


// https://cookinghoil.tistory.com/114

const ModalVideoIU = ({ ModalCb, isModalOpen, isBg = false, isTuto = false }: SG.ModalProps) => {
  const isMobile = (window.innerWidth < 570);
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '400px';
  const [branchName, setBranchName] = useState('');
  const [videoFile, setVideoFile] = useState<any>(null);
  const [videoSub1, setVideoSub1] = useState<any>(null);
  const [videoSub2, setVideoSub2] = useState<any>(null);
  const [imgFile, setImgFile] = useState(null);


  const handleChangeFile = (event: any) => {
    setVideoFile(event.target.files[0]);
  };



  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title={isBg ? "배경음악 변경" : "동영상 추가"}
      onOk={() => { ModalCb(false, {}) }}
      onCancel={() => { ModalCb(false, {}) }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>


        {
          !isBg ? (
            <>
              <div className="modalIpWrap" style={{ marginTop: '0px' }}>
                <span className="modalIpTitle">제 목</span>
                <input type="text" className="modalIp" required placeholder="입력해주세요"
                  value={branchName} onChange={(evt) => setBranchName(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '10px', borderBottomWidth: '0px' }}>
                <span className="modalIpTitle">메인 영상</span>
              </div>
              <div className="modalIpWrap" style={{ marginTop: '-5px' }}>
                <input type="file" id="ex_file" accept={isBg ? "audio/mp3" : "video/mp4,video/mkv, video/x-m4v,video/*"} placeholder={isBg ? "mp3 선택" : "비디오 선택"} onChange={(event: any) => { setVideoFile(event.target.files[0]); }} multiple={false} />
              </div>

              {
                !isTuto && (
                  <>
                    <div className="modalIpWrap" style={{ marginTop: '10px', borderBottomWidth: '0px' }}>
                      <span className="modalIpTitle">서브 영상</span>
                    </div>
                    <div className="modalIpWrap" style={{ marginTop: '-5px' }}>
                      <input type="file" id="ex_filesub1" accept={isBg ? "audio/mp3" : "video/mp4,video/mkv, video/x-m4v,video/*"} placeholder={isBg ? "mp3 선택" : "비디오 선택"} onChange={(event: any) => { setVideoSub1(event.target.files[0]); }} multiple={false} />
                    </div>
                    <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                      <input type="file" id="ex_filesub2" accept={isBg ? "audio/mp3" : "video/mp4,video/mkv, video/x-m4v,video/*"} placeholder={isBg ? "mp3 선택" : "비디오 선택"} onChange={(event: any) => { setVideoSub2(event.target.files[0]); }} multiple={false} />
                    </div>
                  </>
                )
              }
            </>

          ) : (
            <div className="modalIpWrap" style={{ marginTop: '20px' }}>
              <input type="file" id="ex_file" accept={isBg ? "audio/mp3" : "video/mp4,video/mkv, video/x-m4v,video/*"} placeholder={isBg ? "mp3 선택" : "비디오 선택"} onChange={handleChangeFile} multiple={false} />
            </div>
          )
        }


        {
          !isBg && (
            <div className="modalIpWrap" style={{ marginTop: '10px' }}>
              {/* 배너 추가용 */}
              <BannerItem2 item={{ url: "", uid: "", anchor: "" }} width={"100%"} height={"200px"} marginTop={"0px"} setImgFile={setImgFile} />
            </div>
          )
        }

        <div className="modalSaveBtn" onClick={() => {
          if (isBg) {
            if (videoFile === null) { return Modal.warning({ content: 'MP3 파일을 선택해주세요.' }); };
            if (!((videoFile.name).includes("mp3"))) { return Modal.warning({ content: 'MP3 파일을 선택해주세요.' }); };
            ModalCb(true, { videoFile });
          } else {
            if (videoFile === null) { return Modal.warning({ content: '메인 영상을 선택해주세요.' }); };
            if (imgFile === null) { return Modal.warning({ content: '썸네일을 선택해주세요.' }); };
            if (MyUtil._isNull(branchName)) { return Modal.warning({ content: '제목을 선택해주세요.' }); };
            ModalCb(true, { videoFile, imgFile, name: branchName, videoSub1, videoSub2 });
          }
        }}>
          <span className="loginBtnTxt">저 장</span>
        </div>
      </div>
    </Modal>
  );
}
export default ModalVideoIU;