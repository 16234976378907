import { useCallback, useState ,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import Layout from '../constants/Layout';
import BtnHamburger from "./BtnHamburger";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import * as MyUtil from "../constants/MyUtil";

const NavTopBar = ({ location }: any) => {
  const dispatch = useDispatch()
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [isSideMenu, setIsSideMenu] = useState(false);
  
  useEffect(() => {
    // MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
  }, []);

  const SideMenuOpen = useCallback((isClose) => {
    const sideBox = document.querySelector('.sideBox');
    const appContainer = document.querySelector('.appContainer');
    const NavTopBar = document.querySelector('.NavTopBar');

    if(isClose){
      sideBox?.classList.remove('sideBox-open');
      appContainer?.classList.remove('appContainer-sideOpen');
      NavTopBar?.classList.remove('NavTopBar-sideOpen');
      setIsSideMenu(false);

    }else{
      if (!isSideMenu) {
        sideBox?.setAttribute('class', 'sideBox sideBox-open');
        appContainer?.setAttribute('class', 'appContainer appContainer-sideOpen');
        NavTopBar?.setAttribute('class', 'NavTopBar b-blur50 NavTopBar-sideOpen');
  
      } else {
        sideBox?.classList.remove('sideBox-open');
        appContainer?.classList.remove('appContainer-sideOpen');
        NavTopBar?.classList.remove('NavTopBar-sideOpen');
      };
      setIsSideMenu(!isSideMenu);
    }
  }, [isSideMenu]);

  // SBDayProgram
  return (
    (location?.pathname === '/Login' || location?.pathname === '/' || location?.pathname === '/login' || location?.pathname.indexOf('/SB') !== -1) ? <div></div> : (
      <div className="NavTopBar b-blur50" style={{ height: Layout.topBar }}>
        <div style={{ width: '40px', height: '40px', cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '9px', marginTop: '5px' }} onClick={() => { SideMenuOpen(false); }}>
          <BtnHamburger toggle={isSideMenu} />
        </div>

        <div className='b-empty-row'></div>

        <span className="NavTopBar-logout" onClick={() => {
          Modal.warning({ content: '정상적으로 로그아웃 되었습니다.'});

          SideMenuOpen(true);

          if(rxLoginInfo?.c_gb === 'a'){
            dispatch(allActions.logOut());
            return history.push({ pathname: '/login' });
          }else{
            dispatch(allActions.logOut());
            return history.push({ pathname: '/SBLogin' });
          }
        }}>{'로그아웃'}</span>
      </div>
    )
  );
}

export default NavTopBar;