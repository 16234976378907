import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import AWS from 'aws-sdk';
import { Modal } from 'antd';
import { useMutation } from 'react-query';
import ReactPlayer from 'react-player/lazy';
import SBDayProgramEx from "./SBDayProgramEx";
import * as MyUtil from "../constants/MyUtil";
import Loader from '../component/Loader';
import Config from '../constants/Config';
import SBDayProgramTutorial from './SBDayProgramTutorial';
import { useSelector, useDispatch } from 'react-redux';
import allActions from "../component/redux/allActions";
import { RootState } from '../component/redux/rootReducer';
import ModalVideoIU from '../component/ModalVideoIU';
import * as ServerApi from "../constants/ServerApi";
import ModalBranchIU from "../component/ModalBranchIU";

AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_AWSAccessKeyId}`,
  secretAccessKey: `${process.env.REACT_APP_AWSSecretKey}`
});

const myBucket = new AWS.S3({
  params: { Bucket: Config.S3_BUCKET },
  region: Config.S3_REGION,
});


const SBDayProgram = ({ location }: any) => {
  const dispatch = useDispatch()
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [pageType, setPageType] = useState<'exercise' | 'tutorial'>('exercise');
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [videoFile, setVideoFile] = useState<any>(null);

  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
    setLoading(false);
  }, []);


  const ModalVideoCb = useCallback((isOk, jData) => {
    setIsVideoIuModalOpen(false);

    if (isOk) {
      setTimeout(() => {
        setLoading(true);
        const nowDate = new Date();

        const videoName = 'bg/' + nowDate.getTime() + '_' + jData.videoFile.name;
        const videoParams = { Body: jData.videoFile, Bucket: Config.S3_BUCKET, Key: videoName };

        myBucket.putObject(videoParams)
          .on('success', (evt: any) => {
            m_mng_config_i({ center_no: rxLoginInfo.center_no, config_type: 'c1', file_nm: process.env.REACT_APP_AWS_S3_URL + videoName });
          })
          .send((err) => {
            if (err) Modal.warning({ content: 'ModalVideoCb ERROR 11 : ' + err});
            setLoading(false);
          })
      }, 300);
    };
  }, []);


  const { mutate: m_mng_config_i } = useMutation(ServerApi.m_mng_config_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_config_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_config_i', result);

      if (isOk) {
        Modal.warning({ content: "정상적으로 등록이 완료되었습니다!"});
        const tempInfo = { ...rxLoginInfo };
        tempInfo.center_file_nm = params.file_nm;
        dispatch(allActions.setRxLoginInfo(tempInfo));

        if (!MyUtil._isNull(data.file_nm)) {
          DeleteS3object(data.file_nm);
        };
      } else {
        MyUtil._alertMsg('m_mng_config_i', data);
      };
    }
  });

  const DeleteS3object = useCallback((getVideoUrl) => {
    setLoading(true);

    const arrVideoUrl = getVideoUrl.split("/");
    const videoName = arrVideoUrl[arrVideoUrl.length - 2] + '/' + arrVideoUrl[arrVideoUrl.length - 1]
    const videoParams = { Bucket: Config.S3_BUCKET, Key: videoName };

    myBucket.deleteObject(videoParams)
      .on('success', (evt) => {

      })
      .send((err) => {
        if (err) Modal.warning({ content: 'DeleteS3object ERROR 22 : ' + err});
        setLoading(false);
      })

  }, []);


  const { mutate: m_mng_center_u } = useMutation(ServerApi.m_mng_center_u, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_u', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_u', result);

      if (isOk) {
        Modal.warning({ content: "정상적으로 수정이 완료되었습니다!"});
      } else {
        MyUtil._alertMsg('m_mng_center_u', data);
      };
    }
  });

  const ModalCb = useCallback((isOk, jData) => {
    setIsClassModalOpen(false);
    if (isOk) {
      const tempInfo = { ...rxLoginInfo };
      tempInfo.br_time = jData.br_time;
      tempInfo.ex_time = jData.ex_time;
      dispatch(allActions.setRxLoginInfo(tempInfo));
      m_mng_center_u(jData);
    };
  }, []);



  return (
    <div className="container-zero" style={{ backgroundColor: '#000000' }}>
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-row" style={{ marginTop: '20px', justifyContent: 'center' }}>
              <div className={pageType === 'exercise' ? 'sbdayprogram-menuBtn-on' : 'sbdayprogram-menuBtn-off'} onClick={() => { setPageType('exercise') }}>운동 영상</div>
              <div className={pageType === 'tutorial' ? 'sbdayprogram-menuBtn-on' : 'sbdayprogram-menuBtn-off'} onClick={() => { setPageType('tutorial') }}>튜토리얼</div>

              <div className='sbdayprogram-menuBtn-off' onClick={() => { setIsVideoIuModalOpen(true); }}>배경음악 등록</div>

              <div className='sbdayprogram-menuBtn-off' onClick={() => { setIsClassModalOpen(true); }}>운동시간 설정</div>
              {
                rxLoginInfo?.pay_type === Config.TYPE_PREMIUM && (
                  <div className='sbdayprogram-menuBtn-off' onClick={() => { history.push({ pathname: '/SBLogin', state: { isAdmin: true } }); }}>프로그램셋팅</div>
                )
              }
            </div>




            {/* <div className="modalIpWrap" style={{ marginTop: '-5px' }}>
              <input type="file" id="ex_file" accept={"video/mp4,video/mkv, video/x-m4v,video/*"} placeholder={"비디오 선택"} onChange={(event: any) => {
                const fileURL = URL.createObjectURL(event.target.files[0]);
                console.log('fileURL : '+fileURL)
                setVideoFile(fileURL); }} multiple={false} />
                <div style={{color:'#ffffff'}}>{videoFile}..</div>
            </div> */}



            {
              !MyUtil._isNull(videoFile) && (
                <ReactPlayer
                  width={'100%'}
                  height={'45vh'}
                  url={videoFile}
                  playing={true}
                  controls={false}
                  muted={false}
                  progressInterval={1000}
                  pip={false}
                  loop={true}
                  style={{backgroundColor:'#ff00ff'}}
                />

              )
            }

            {
              pageType === 'exercise' ? (
                <SBDayProgramEx />
              ) : (
                <SBDayProgramTutorial />
              )
            }
          </>
        )
      }

      {
        isVideoIuModalOpen && (
          <ModalVideoIU ModalCb={ModalVideoCb} isModalOpen={isVideoIuModalOpen} isBg={true} />
        )
      }
      {
        isClassModalOpen && (
          <ModalBranchIU ModalCb={ModalCb} isModalOpen={isClassModalOpen} updateData={rxLoginInfo} isAdmin={false} />
        )
      }
    </div>
  )

};

export default SBDayProgram;