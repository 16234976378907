import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ResponsiveBar } from '@nivo/bar'
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import { QueryFunctionContext, useQuery } from 'react-query';
import Sprintf from 'sprintf-js';
const sprintf = Sprintf.sprintf;




const Sales = ({ location }: any) => {
  const isMobile = MyUtil.isMobile;
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [pageType, setPageType] = useState('month'); // month , year
  const [startTime, setStartTime] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [arrSales, setArrSales] = useState<null | []>([]);
  const [arrYear, setArrYear] = useState<[]>([]);
  const [sumAmountMonth, setSumAmountMonth] = useState<string>('');
  const [sumAmountYear, setSumAmountYear] = useState<string>('');
  const headerData = ['지점', '요금제', '결제 금액', '결제일', '이용 기간'];
  const chartData = [
    // { "sales_month": "1월", "sales_price": Math.floor(Math.random() * 1000) + 10 },
    { "amount": "100000", "mon": "2022-04" }, { "amount": "180000", "mon": "2022-05" }
  ];


  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
    const today = new Date();
    setStartTime(sprintf("%04d-%02d", today.getFullYear(), today.getMonth() + 1))
  }, []);


  const m_mng_center_sales_query = useQuery([arrSales, startTime], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_center_sales({ center_no: '', mon: startTime }); }, {
    retry: 1, enabled: arrSales === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_sales', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_sales', result);

      if (isOk) {
        const newArr1: any = [...data.array1];
        const newArr2: any = [...data.array2];

        let sumMonth: any = 0;
        for (var value of newArr1) {
          sumMonth = parseInt(sumMonth) + parseInt(value.amount);
        }

        let sumYear: any = 0;
        for (var value of newArr2) {
          sumYear = parseInt(sumYear) + parseInt(value.amount);
        }

        setSumAmountMonth(sumMonth);
        setSumAmountYear(sumYear);
        setArrSales(newArr1);
        setArrYear(newArr2);
      } else {
        setArrSales(null);
        setArrYear([]);
        MyUtil._alertMsg('m_mng_center_sales', data);
      };
    },
  });




  return (
    <div className="container">
      <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
        <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
        <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

        <div className='b-empty-row'></div>

        {
          isMobile && <div style={{ width: '530px', height: '15px' }}></div>
        }

        <div className="titleBtn-Con" style={{ minWidth: '100px', marginRight: '5px' }}>
          <input type="month" className="titleBtn-calendarIp solidSelect" placeholder="입력해주세요" value={startTime} onChange={({ target }) => { setStartTime(target.value); }}></input>
        </div>
      </div>

      <div className="container-wrap" style={{ marginBottom: '10px', alignItems: 'center', alignContent: 'center' }}>
        <span className="Sales-price" style={{ color: pageType === 'month' ? '#e37a29' : '#5e5e5e', borderColor: pageType === 'month' ? '#e37a29' : '#5e5e5e' }} onClick={() => { setPageType('month'); }}>{startTime} 매출 : {MyUtil._toThousandsCommas(sumAmountMonth)}원</span>
        <span className="Sales-price" style={{ color: pageType === 'year' ? '#e37a29' : '#5e5e5e', borderColor: pageType === 'year' ? '#e37a29' : '#5e5e5e' }} onClick={() => { setPageType('year'); }}>총 매출 : {MyUtil._toThousandsCommas(sumAmountYear)}원</span>
      </div>

      {
        pageType === 'month' ? (
          <div className="Sales-table">
            {/* 헤더 */}
            {
              headerData.map((hdItem, hdIdx) => (<div key={hdIdx} className="Sales-table-header">{hdItem}</div>))
            }

            {/* DATA */}
            {
              arrSales?.map((item: any, idx) => (
                <React.Fragment key={idx}>
                  <div className='Sales-table-cd'>{item.center_nm}</div>
                  <div className='Sales-table-cd'>{item.pay_type_nm}</div>
                  <div className='Sales-table-cd'>{MyUtil._toThousandsCommas(item.amount)}</div>
                  <div className='Sales-table-cd'>{item.reg_date}</div>
                  <div className='Sales-table-cd'>{item.use_time}</div>
                </React.Fragment>
              ))
            }
          </div>

        ) : (
          <ResponsiveBar
            data={arrYear}
            keys={['amount']}
            indexBy="mon"
            margin={{ top: 50, right: 30, bottom: 80, left: 80 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            defs={[]}
            fill={[]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '매출 날짜(월)',
              legendPosition: 'middle',
              legendOffset: 38
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '매출(원)',
              legendPosition: 'middle',
              legendOffset: -50,
              format: value => `${MyUtil._toThousandsCommas(value)}`,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]]
            }}
            legends={[
              // {
              //   dataFrom: 'keys',
              //   anchor: 'bottom-right',
              //   direction: 'column',
              //   justify: false,
              //   translateX: 120,
              //   translateY: 0,
              //   itemsSpacing: 2,
              //   itemWidth: 100,
              //   itemHeight: 20,
              //   itemDirection: 'left-to-right',
              //   itemOpacity: 0.85,
              //   symbolSize: 20,
              //   effects: [
              //     {
              //       on: 'hover',
              //       style: {
              //         itemOpacity: 1
              //       }
              //     }
              //   ]
              // }
            ]}
            role="application"
            ariaLabel="그래비티 월 매출"
            labelFormat={v => `${MyUtil._toThousandsCommas(v)}`}
            valueFormat={v => `${MyUtil._toThousandsCommas(v)}`}
            barAriaLabel={function (e) { return "" }}
            // barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in sales_month: " + e.indexValue }}
            tooltip={({ id, value, color }) => (
              <div
                style={{
                  padding: 12,
                  color,
                  background: '#222222',
                }}
              >
                <span>월 매출</span>
                <br />
                <strong>{value}원</strong>
              </div>
            )}
          />
        )
      }
    </div>
  )

};

export default Sales;
