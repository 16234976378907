import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AWS from 'aws-sdk';
import { Modal } from 'antd';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import ModalVideoIU from '../component/ModalVideoIU';
import Config from '../constants/Config';
import Loader from '../component/Loader';


AWS.config.update({
  accessKeyId: `${process.env.REACT_APP_AWSAccessKeyId}`,
  secretAccessKey: `${process.env.REACT_APP_AWSSecretKey}`
});

const myBucket = new AWS.S3({
  params: { Bucket: Config.S3_BUCKET },
  region: Config.S3_REGION,
});



const ProgramsCateVideoAdd = ({ location }: any) => {
  const isTutorial = location?.pathname === '/TutorialVideo' ? true : false;
  const isMobile = MyUtil.isMobile;
  const history = useHistory();
  const mv_category_no = MyUtil._isNull(location?.state?.mv_category_no) ? '' : location?.state?.mv_category_no;
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [arrVideItem, setArrVideItem] = useState<null | []>([]);
  const [arrTutoItem, setArrTutoItem] = useState<null | []>([]);

  const putObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket.putObject(params)
        .on('success', (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        })
    });
  };

  const deleteObjectWrapper = (params: any) => {
    return new Promise((resolve, reject) => {
      myBucket.deleteObject(params)
        .on('success', (evt) => {
          if (evt) resolve(evt);
        })
        .send((err) => {
          if (err) reject(err);
        })
    });
  };

  useEffect(() => {
    if (isTutorial) {
      setArrVideItem(null)
    } else {
      setArrTutoItem(null)
    }
  }, []);


  const m_mng_tu = useQuery([arrTutoItem], (ctx: QueryFunctionContext) => {
    return ServerApi.m_mng_tu({});
  }, {
    retry: 1, enabled: arrTutoItem === null ? false : true,  // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_tu', error); },
    onSuccess: (result: any) => {
      setLoading(false); if (MyUtil._isNull(result)) { return; };
      const { isOk, data } = MyUtil._isResultSucces('m_mng_tu', result);

      if (isOk) {
        setArrTutoItem(data.array);
      } else {
        setArrTutoItem(null);
        MyUtil._alertMsg('m_mng_tu', data);
      };
    },
  });


  const m_mng_mv_query = useQuery([arrVideItem], (ctx: QueryFunctionContext) => {
    if (MyUtil._isNull(mv_category_no)) { return null; };
    return ServerApi.m_mng_mv({ mv_category_no: mv_category_no });
  }, {
    retry: 1,// enabled: arrVideItem === null ? false : true,  // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv', error); },
    onSuccess: (result: any) => {
      setLoading(false); if (MyUtil._isNull(result)) { return; };
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv', result);

      if (isOk) {
        setArrVideItem(data.array);
      } else {
        setArrVideItem(null);
        MyUtil._alertMsg('m_mng_mv', data);
      };
    },
  });


  const DeleteS3object = useCallback(async (getVideoUrl, getImgUrl, getMovNo, getVideoUrl2, getVideoUrl3) => {
    setLoading(true);

    try {
      const arrImgUrl = getImgUrl.split("/");
      const imgName = arrImgUrl[arrImgUrl.length - 2] + '/' + arrImgUrl[arrImgUrl.length - 1];
      const imgParams = { Bucket: Config.S3_BUCKET, Key: imgName };
      const result: any = await deleteObjectWrapper(imgParams);

      if (!MyUtil._isNull(getVideoUrl)) {
        const arrVideoUrl = getVideoUrl.split("/");
        const videoName = arrVideoUrl[arrVideoUrl.length - 2] + '/' + arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoName };
        const result: any = await deleteObjectWrapper(videoParams);
      };

      if (!MyUtil._isNull(getVideoUrl2)) {
        const arrVideoUrl = getVideoUrl2.split("/");
        const videoName = arrVideoUrl[arrVideoUrl.length - 2] + '/' + arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoName };
        const result: any = await deleteObjectWrapper(videoParams);
      };

      if (!MyUtil._isNull(getVideoUrl3)) {
        const arrVideoUrl = getVideoUrl3.split("/");
        const videoName = arrVideoUrl[arrVideoUrl.length - 2] + '/' + arrVideoUrl[arrVideoUrl.length - 1];
        const videoParams = { Bucket: Config.S3_BUCKET, Key: videoName };
        const result: any = await deleteObjectWrapper(videoParams);
      };

      if (isTutorial) {
        m_mng_tu_d({ tu_no: getMovNo });
      } else {
        m_mng_mv_d({ mv_no: getMovNo });
      };

    } catch (err) {
      if (err) Modal.warning({ content: 'deleteObjectWrapper ERROR : ' + err });
      setLoading(false);
    };

  }, [mv_category_no, isTutorial]);


  const ModalVideoCb = useCallback((isOk, jData) => {
    setIsVideoIuModalOpen(false);

    if (isOk) {
      setTimeout(async () => {
        setLoading(true);
        const nowDate = new Date();

        let imgName = '', sub01name = '', sub02name = '';
        const videoName = 'video/' + nowDate.getTime() + '_' + jData.videoFile.name;
        const videoParams = { Body: jData.videoFile, Bucket: Config.S3_BUCKET, Key: videoName };

        try {
          const putObj01: any = await putObjectWrapper(videoParams);

          if (!MyUtil._isNull(jData?.imgFile?.name)) {
            imgName = 'img/' + nowDate.getTime() + '_' + jData.imgFile.name;
            const putParams = { Body: jData.imgFile, Bucket: Config.S3_BUCKET, Key: imgName };
            const result: any = await putObjectWrapper(putParams);
          };

          if (!MyUtil._isNull(jData?.videoSub1?.name)) {
            sub01name = 'video/' + nowDate.getTime() + 's1_' + jData.videoSub1.name;
            const putParams = { Body: jData.videoSub1, Bucket: Config.S3_BUCKET, Key: sub01name };
            const result: any = await putObjectWrapper(putParams);
          };

          if (!MyUtil._isNull(jData?.videoSub2?.name)) {
            sub02name = 'video/' + nowDate.getTime() + 's2_' + jData.videoSub2.name;
            const putParams = { Body: jData.videoSub2, Bucket: Config.S3_BUCKET, Key: sub02name };
            const result: any = await putObjectWrapper(putParams);
          };

          if (isTutorial) {
            m_mng_tu_i({ tu_no: '', tu_nm: jData.name, tu_rep_img: process.env.REACT_APP_AWS_S3_URL + imgName, tu_url: process.env.REACT_APP_AWS_S3_URL + videoName });
          } else {
            m_mng_mv_i({
              mv_category_no: mv_category_no, mv_no: '', mv_nm: jData.name, mv_rep_img: process.env.REACT_APP_AWS_S3_URL + imgName,
              mv_url: process.env.REACT_APP_AWS_S3_URL + videoName,
              mv_url2: MyUtil._isNull(sub01name) ? '' : process.env.REACT_APP_AWS_S3_URL + sub01name,
              mv_url3: MyUtil._isNull(sub02name) ? '' : process.env.REACT_APP_AWS_S3_URL + sub02name,
            });
          };

          setLoading(false);

        } catch (err) {
          if (err) Modal.warning({ content: 'ModalVideoCb ERROR : ' + err });
          setLoading(false);
        };
      }, 300);
    };
  }, [mv_category_no, isTutorial]);


  const { mutate: m_mng_mv_i } = useMutation(ServerApi.m_mng_mv_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_i', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 등록이 완료되었습니다!" });

        // ---- 리스트 재조회 
        if (isTutorial) {
          setArrTutoItem([])
        } else {
          setArrVideItem([])
        }
      } else {
        MyUtil._alertMsg('m_mng_mv_i', data);
      };
    }
  });


  const { mutate: m_mng_tu_i } = useMutation(ServerApi.m_mng_tu_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_tu_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_tu_i', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 등록이 완료되었습니다!" });
        setArrVideItem([]);   // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_tu_i', data);
      };
    }
  });



  const { mutate: m_mng_mv_d } = useMutation(ServerApi.m_mng_mv_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_d', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 삭제되었습니다!" });
        // ---- 리스트 재조회 
        if (isTutorial) {
          setArrTutoItem([])
        } else {
          setArrVideItem([])
        }
      } else {
        MyUtil._alertMsg('m_mng_mv_d', data);
      };
    }
  });

  const { mutate: m_mng_tu_d } = useMutation(ServerApi.m_mng_tu_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_tu_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_tu_d', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 삭제되었습니다!" });
        // ---- 리스트 재조회 
        if (isTutorial) {
          setArrTutoItem([])
        } else {
          setArrVideItem([])
        }
      } else {
        MyUtil._alertMsg('m_mng_tu_d', data);
      };
    }
  });



  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <div className="container-root" >
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
              <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              <div className="titleBtn-Con" onClick={() => { setIsVideoIuModalOpen(true) }}>
                <span className='titleBtn-text'>동영상 추가</span>
              </div>
            </div>


            {
              isTutorial ? (
                <div className="container-wrap">
                  {
                    !MyUtil._isNull(arrTutoItem) && arrTutoItem?.map((item: any, idx) => (
                      <div key={idx} className={isMobile ? 'itembox03 b-width-100' : 'itembox03 b-width-25'} style={{ backgroundColor: '#ffffff', position: 'relative', overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '160px', objectFit: 'cover', borderRadius: '5px' }} src={isTutorial ? item.tu_rep_img : item.mv_rep_img} alt="logo image" onClick={() => { window.open(isTutorial ? item.tu_url : item.mv_url, '_blank') }} />

                        <div style={{ width: '100%', height: '40px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.40)', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <span style={{ fontSize: '0.85em', color: '#ffffff', flex: 1, paddingLeft: '10px' }}>{isTutorial ? item.tu_nm : item.mv_nm}</span>
                          <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '10px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }}
                            onClick={() => {
                              Modal.confirm({
                                okText: "확인", cancelText: "취소",
                                title: '영상 삭제',
                                content: '영상을 삭제할까요?',
                                onOk() {
                                  if (isTutorial) {
                                    DeleteS3object(item.tu_url, item.tu_rep_img, item.tu_no, '', '');
                                  } else {
                                    DeleteS3object(item.mv_url, item.mv_rep_img, item.mv_no, item.mv_url2, item.mv_url3);
                                  };
                                },
                                onCancel() { },
                              });
                            }}>{'삭제'}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>

              ) : (
                <div className="container-wrap">
                  {
                    !MyUtil._isNull(arrVideItem) && arrVideItem?.map((item: any, idx) => (
                      <div key={idx} className={isMobile ? 'itembox03 b-width-100' : 'itembox03 b-width-25'} style={{ backgroundColor: '#ffffff', position: 'relative', overflow: 'hidden' }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '5px' }} src={isTutorial ? item.tu_rep_img : item.mv_rep_img} alt="logo image" onClick={() => { window.open(isTutorial ? item.tu_url : item.mv_url, '_blank') }} />

                        <div style={{ width: '100%', height: '40px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.40)', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                          <span style={{ fontSize: '0.85em', color: '#ffffff', flex: 1, paddingLeft: '10px' }}>{isTutorial ? item.tu_nm : item.mv_nm}</span>
                          <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '10px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }}
                            onClick={() => {
                              Modal.confirm({
                                okText: "확인", cancelText: "취소",
                                title: '영상 삭제',
                                content: '영상을 삭제할까요?',
                                onOk() {
                                  if (isTutorial) {
                                    DeleteS3object(item.tu_url, item.tu_rep_img, item.tu_no, '', '');
                                  } else {
                                    DeleteS3object(item.mv_url, item.mv_rep_img, item.mv_no, item.mv_url2, item.mv_url3);
                                  };
                                },
                                onCancel() { },
                              });
                            }}>{'삭제'}</span>
                        </div>
                      </div>
                    ))
                  }
                </div>

              )
            }
          </div>
        )
      }

      {
        isVideoIuModalOpen && (
          <ModalVideoIU ModalCb={ModalVideoCb} isModalOpen={isVideoIuModalOpen} isTuto={isTutorial} />
        )
      }
    </div>
  )
};

export default ProgramsCateVideoAdd;