import { useEffect, useCallback, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import ReactAudioPlayer from 'react-audio-player';
import { faVolumeMute, faVolumeHigh, faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import Vimeo from '@u-wave/react-vimeo';
import * as MyUtil from "../constants/MyUtil";
import logoArrowOnly from '../img/logo_arrow_only.png';
import { RootState } from '../component/redux/rootReducer';
import Loader from '../component/Loader';


const SBVideoPlay = ({ location }: any) => {
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [isTutorial, setIsTutorial] = useState(MyUtil._isNull(location?.state?.isTutorial) ? false : location?.state?.isTutorial);
  const [tutoUrl, setTutoUrl] = useState(MyUtil._isNull(location?.state?.tutoUrl) ? '' : location?.state?.tutoUrl);
  const [selectProgram, setSelectProgram] = useState(MyUtil._isNull(location?.state?.selectProgram) ? '' : location?.state?.selectProgram);
  const [playIndex, setPlayIndex] = useState(0);
  const [playStatus, setPlayStatus] = useState('p');
  const [isBgSound, setIsBgSound] = useState(false);
  const [isPlayEx, setIsPlayEx] = useState(false);
  const [exTimer, setExTimer] = useState<number>(3);
  const audioPlayer = useRef<any>(null);
  const interval = useRef<any>(null);
  const mParams = useRef<any>({ exTimer: 3, exStatus: 'p', playIndex: 0 });  // exTstatus : p 준비 , e 운동 , r 쉬는시간
  let EX_TIME = MyUtil._isNull(rxLoginInfo.ex_time) ? 0 : parseInt(rxLoginInfo.ex_time);
  let REST_TIME = MyUtil._isNull(rxLoginInfo.br_time) ? 0 : parseInt(rxLoginInfo.br_time);
  const [mvUrl1, setMvUrl1] = useState<any>('');
  const [mvUrl2, setMvUrl2] = useState<any>('');
  const [mvUrl3, setMvUrl3] = useState<any>('');
  const [loading, setLoading] = useState<boolean>(true);
  const isCache = false;
  const getCacheName = 'csVideo';


  useEffect(() => {
    if (isTutorial) {
      EX_TIME = 0;
      REST_TIME = 0;
    };

    async function fetchData() {
      if (!isTutorial) {
        if (isCache) {
          const mvUrl01: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url);
          setMvUrl1(mvUrl01);
          const mvUrl02: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url2);
          setMvUrl2(mvUrl02);
          const mvUrl03: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url3);
          setMvUrl3(mvUrl03);
        } else {
          setMvUrl1(selectProgram?.in_array[playIndex]?.mv_url);
          setMvUrl2(selectProgram?.in_array[playIndex]?.mv_url2);
          setMvUrl3(selectProgram?.in_array[playIndex]?.mv_url3);
        };
      }
      setLoading(false);

      if (MyUtil._isNull(EX_TIME)) {
        setIsPlayEx(true);
        setPlayStatus('e');
      };

      setTimeout(() => { setIsBgSound(true); }, 500);
    };


    fetchData();

    return () => {
      // ***  Blob URL 해제
      // window.URL.revokeObjectURL();
      if (interval.current) { clearInterval(interval.current); };
    };
  }, []);




  const prefetch_file = (getCacheUrl: any) => {
    return new Promise(async (resolve, reject) => {
      if (MyUtil._isNull(getCacheUrl)) { return resolve(''); };

      const xhr: any = new XMLHttpRequest();
      xhr.open("GET", getCacheUrl, true);
      xhr.responseType = "blob";

      xhr.addEventListener("load", function () {
        if (xhr.status === 200) {
          var URL = window.URL || window.webkitURL;
          var blob_url = URL.createObjectURL(xhr.response);
          console.log('blob_url : ' + blob_url)
          resolve(blob_url);
        } else {
          resolve('');
        };
      }, false);

      var prev_pc = 0;
      xhr.addEventListener("progress", function (event: any) {
        if (event.lengthComputable) {
          var pc = Math.round((event.loaded / event.total) * 100);
          if (pc != prev_pc) {
            prev_pc = pc;
            // progress_callback(pc);
          };
        };
      });
      xhr.send();
    });
  };


  // const prefetch_file = (getCacheUrl: any) => {
  //   return new Promise(async (resolve, reject) => {
  //     if (MyUtil._isNull(getCacheUrl)) { return resolve(''); };

  //     const xhr: any = new XMLHttpRequest();
  //     xhr.open("GET", getCacheUrl, true);
  //     xhr.responseType = "blob";

  //     xhr.addEventListener("load", function () {
  //       if (xhr.status === 200) {
  //         var URL = window.URL || window.webkitURL;
  //         var blob_url = URL.createObjectURL(xhr.response);
  //         resolve(blob_url);
  //       } else {
  //         resolve('');
  //       };
  //     }, false);

  //     var prev_pc = 0;
  //     xhr.addEventListener("progress", function (event: any) {
  //       if (event.lengthComputable) {
  //         var pc = Math.round((event.loaded / event.total) * 100);
  //         if (pc != prev_pc) {
  //           prev_pc = pc;
  //           // progress_callback(pc);
  //         };
  //       };
  //     });
  //     xhr.send();
  //   });
  // };




  // const prefetch_file = (getCacheUrl: any) => {
  //   return new Promise(async (resolve, reject) => {
  //     if (MyUtil._isNull(getCacheUrl)) { return resolve(''); };

  //     const cacheStorage = await caches.open(getCacheName);
  //     const responsedCache:any = await cacheStorage.match(getCacheUrl);
  //     if (responsedCache !== undefined) {
  //       var URL = window.URL || window.webkitURL;
  //       var blob_url = URL.createObjectURL(responsedCache);
  //       resolve(blob_url);

  //     } else {

  //       const xhr:any = new XMLHttpRequest();
  //       xhr.open("GET", getCacheUrl, true);
  //       xhr.responseType = "blob";

  //       xhr.addEventListener("load", function () {
  //         if (xhr.status === 200) {
  //           // *** 캐시 스토리지 저장
  //           cacheStorage.put(getCacheUrl, xhr.response);

  //           var URL = window.URL || window.webkitURL;
  //           var blob_url = URL.createObjectURL(xhr.response);
  //           resolve(blob_url);
  //         } else {
  //           resolve('');
  //         };
  //       }, false);

  //       var prev_pc = 0;
  //       xhr.addEventListener("progress", function (event:any) {
  //         if (event.lengthComputable) {
  //           var pc = Math.round((event.loaded / event.total) * 100);
  //           if (pc != prev_pc) {
  //             prev_pc = pc;
  //             // progress_callback(pc);
  //           };
  //         };
  //       });
  //       xhr.send();
  //     };
  //   });
  // };



  const timerHandler = useCallback(async (btnStatus) => {
    if (btnStatus === 'stop') {
      setIsPlayEx(false);
      if (interval.current) { clearInterval(interval.current) }

    } else if (btnStatus === 'play') {
      setIsPlayEx(true);
      interval.current = setInterval(function () {
        let nextTime = mParams.current.exTimer - 1;
        let nowStatus = mParams.current.exStatus;
        let nextStatus = nowStatus;

        if (nextTime < 0) {
          if (nowStatus === 'p') {
            nextStatus = 'e';
            nextTime = EX_TIME;
          } else if (nowStatus === 'e') {
            nextStatus = 'r';
            nextTime = REST_TIME;
          } else if (nowStatus === 'r') {
            nextStatus = 'e';
            nextTime = EX_TIME;
            handleNextVideo();
          };
        };

        mParams.current.exStatus = nextStatus;
        mParams.current.exTimer = nextTime;
        setExTimer(nextTime);
        setPlayStatus(nextStatus);
      }, 1000);
    }
  }, []);


  useEffect(() => {
    async function fetchData() {

      if (!isTutorial) {
        setLoading(true);
        if (isCache) {
          const mvUrl01: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url);
          setMvUrl1(mvUrl01);
          const mvUrl02: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url2);
          setMvUrl2(mvUrl02);
          const mvUrl03: any = await prefetch_file(selectProgram?.in_array[playIndex]?.mv_url3);
          setMvUrl3(mvUrl03);
        } else {
          setMvUrl1(selectProgram?.in_array[playIndex]?.mv_url);
          setMvUrl2(selectProgram?.in_array[playIndex]?.mv_url2);
          setMvUrl3(selectProgram?.in_array[playIndex]?.mv_url3);
        };
        setLoading(false);

      }
    };
    fetchData();
  }, [playIndex]);


  useEffect(() => {
    if (!MyUtil._isNull(audioPlayer)) {
      if (isBgSound) {
        audioPlayer.current.audioEl.current.play();
      } else {
        audioPlayer.current.audioEl.current.pause();
      };
    }
  }, [isBgSound]);


  const handleNextVideo = () => {

    // ** 다음 영상 재생
    if (mParams.current.playIndex === (selectProgram?.in_array?.length - 1)) {
      Modal.info({ content: '운동이 종료되었습니다.'});
      if (interval.current) { clearInterval(interval.current); };
      setIsPlayEx(false);
      setPlayIndex(0);
      setPlayStatus('p');
      setExTimer(3);
      mParams.current = { exTimer: 3, exStatus: 'p', playIndex: 0 };
    } else {
      const nextIndex = mParams.current.playIndex + 1;
      mParams.current.playIndex = nextIndex;
      setPlayIndex(nextIndex);
    };
  };

  // const selectVideo = (index: number) => {
  //   setPlayIndex(index);
  // }



  let bgFile = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';
  if (!MyUtil._isNull(rxLoginInfo.center_file_nm)) {
    bgFile = rxLoginInfo.center_file_nm;
  };

  return (
    <div className="container-zero-row" style={{ backgroundColor: '#000000' }}>

      <ReactAudioPlayer
        ref={audioPlayer}
        style={{ display: 'none' }}
        src={bgFile}
        autoPlay
        controls
        loop
        volume={playStatus === 'e' ? 0.2 : 1}
      />

      {
        loading ? (<Loader />) : (
          <>
            <div className='sbvideoplay-left'>
              <img style={{ width: '50px', height: '50px', marginTop: '22px', marginBottom: '7px', cursor: 'pointer' }} src={logoArrowOnly} alt="logo image" onClick={() => { history.push({ pathname: '/SBDayProgram', state: {} }); }} />

              {
                !MyUtil._isNull(EX_TIME) && (
                  <div className="sbvideoplay-leftBtnWrap" style={{ backgroundColor: !isPlayEx ? '#78fc5d' : '#c4c4c4' }} onClick={() => {
                    timerHandler(!isPlayEx ? 'play' : 'stop');
                  }}>
                    <FontAwesomeIcon style={{ width: '22px', height: '22px', marginLeft: !isPlayEx ? '3px' : '0px' }} icon={!isPlayEx ? faPlay : faPause} />
                  </div>
                )
              }

              <div className="sbvideoplay-leftBtnWrap" style={{ backgroundColor: isBgSound ? '#78fc5d' : '#c4c4c4' }} onClick={() => { setIsBgSound(!isBgSound); }}>
                <FontAwesomeIcon style={{ width: '22px', height: '22px' }} icon={isBgSound ? faVolumeHigh : faVolumeMute} />
              </div>

              {
                !isTutorial && selectProgram?.in_array?.map((item: any, idx: number) => (
                  <div key={idx} className="sbvideoplay-leftBtnWrap" style={{ backgroundColor: (idx) === playIndex ? '#ff7a45' : '#fcc01c' }} onClick={() => {
                    mParams.current.playIndex = idx;
                    setPlayIndex(idx);
                  }}>{idx + 1}</div>
                ))
              }

              <div style={{ width: '1px', minHeight: '25px' }}></div>
            </div>


            <div className='sbvideoplay-right'>
              {
                !isTutorial ? (
                  // <p style={{ color: '#ffffff', fontSize: '3rem' }}>Loading...</p>
                  MyUtil._isNull(selectProgram?.in_array) ? (
                    <p style={{ color: '#ffffff', fontSize: '1.2rem' }}>Loading...</p>
                  ) : (

                    <div style={{
                      flexDirection: 'row', display: 'flex', width: '100%', alignItems: 'center', height: '90vh', overflow: 'hidden', justifyContent: 'center',
                      paddingLeft: (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '100px' : '0px'
                    }}>
                      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', justifyContent: 'center' }}>

                        {/* <Vimeo
                          video="https://vimeo.com/725133604"
                          width={500}
                          height={500}
                          // height={(!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '90vh' : '80vh'}
                          autoplay
                          loop={true}
                          controls={false}
                        /> */}

                        <ReactPlayer
                          width={'auto'}
                          height={(!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '90vh' : '80vh'}
                          // url={selectProgram?.in_array[playIndex]?.mv_url}
                          url={mvUrl1}
                          // url={'blob:http://localhost:3000/58af30b0-87c2-4fce-807b-b71a02e141c2'}
                          playing={(playStatus === 'e' && isPlayEx) ? true : false}
                          controls={false}
                          muted={false}
                          progressInterval={1000}
                          pip={false}
                          loop={true}
                        // style={{ marginRight: (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '-100px' : '0px' }}
                        // onEnded={() => { handleNextVideo(selectProgram?.in_array, playIndex); }}
                        />
                      </div>

                      <div style={{ width: '10px', height: '1px', display: 'flex' }}></div>

                      {
                        (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? (
                          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <ReactPlayer
                              width={'auto'}
                              height={'45vh'}
                              // url={selectProgram?.in_array[playIndex]?.mv_url2}
                              url={mvUrl2}
                              playing={(playStatus === 'e' && isPlayEx) ? true : false}
                              controls={false}
                              muted={false}
                              progressInterval={1000}
                              pip={false}
                              loop={true}
                            />


                            {/* <Vimeo
                              video="https://vimeo.com/725133604"
                              width={500}
                              height={500}
                              // height={(!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '90vh' : '80vh'}
                              autoplay
                              loop={true}
                              controls={false}
                            /> */}
                            <div style={{ width: '1px', height: '10px' }}></div>

                            <ReactPlayer
                              width={'auto'}
                              height={'45vh'}
                              // url={selectProgram?.in_array[playIndex]?.mv_url3}
                              url={mvUrl3}
                              playing={(playStatus === 'e' && isPlayEx) ? true : false}
                              controls={false}
                              muted={false}
                              progressInterval={1000}
                              pip={false}
                              loop={true}
                            />
                          </div>

                        ) : MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) ? (
                          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <ReactPlayer
                              width={'auto'}
                              height={'80vh'}
                              // url={selectProgram?.in_array[playIndex]?.mv_url3}
                              url={mvUrl3}
                              playing
                              controls={false}
                              muted={false}
                              progressInterval={1000}
                              pip={false}
                              loop={true}
                            />
                          </div>

                        ) : (
                          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <ReactPlayer
                              width={'auto'}
                              height={'80vh'}
                              // url={selectProgram?.in_array[playIndex]?.mv_url2}
                              url={mvUrl2}
                              playing={(playStatus === 'e' && isPlayEx) ? true : false}
                              controls={false}
                              muted={false}
                              progressInterval={1000}
                              pip={false}
                              loop={true}
                            />
                          </div>
                        )
                      }

                    </div>
                  )
                ) : (
                  <ReactPlayer
                    width={'auto'}
                    height={'94vh'}
                    url={tutoUrl}
                    playing={(playStatus === 'e' && isPlayEx) ? true : false}
                    controls={false}
                    muted={false}
                    progressInterval={1000}
                    pip={false}
                    loop={true}
                  />
                )
              }
            </div>
          </>
        )
      }
      {
        (!MyUtil._isNull(EX_TIME) && !isTutorial) && (
          <div style={{ position: 'absolute', width: '120px', height: '120px', borderRadius: '60px', top: '25px', left: '105px', paddingTop: '5px', backgroundColor: playStatus === 'e' ? '#eb9800' : '#a6a6a6', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '60px', color: '#ffffff', fontWeight: 'bold' }}>{exTimer}</div>
        )
      }
    </div>
  )
};

export default SBVideoPlay;