import * as SG from '../constants/Signature';
import Colors from "../constants/Colors";

const BtnHamburger = ({ toggle }: SG.BtnHamburgerProps) => {
  let addClass = "nav-icon1";
  toggle === true && (addClass = "nav-icon1 nav-icon1open");
  return (
    <div className={addClass}>
      <span style={{background:Colors.mainColor}}></span>
      <span style={{background:Colors.mainColor}}></span>
      <span style={{background:Colors.mainColor}}></span>
    </div>
  );
};

export default BtnHamburger;