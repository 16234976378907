import * as MyUtil from "../constants/MyUtil";
import imgDefault from "../img/bg_gym.jpg";
import '../css/DragItem.css';


const DragItem = ({ item, idx, arrProVideos, setArrProVideos }: any) => {

  return (
    <div className={MyUtil.isMobile ? 'dndItemWrap  b-width-100' : 'dndItemWrap b-width-200p'}>
      <div className='videoDndWrap-in'>
        <div className="videoDndWrap-row" style={{ marginTop: '14px' }}>
          <span className={"handle"} style={{ padding: '3px 6px', cursor: 'pointer' }}>{`:::`}</span>
          <span className="modalProgramDndIndex">{(parseInt(idx) + 1) + '번 '}</span>
          <span className="dndItem-key">{item.mv_no}</span>

          <div style={{ display: 'flex', flex: '1' }}></div>

          <span className="videoDndWrap-del" onClick={() => {
            arrProVideos.splice(idx, 1);
            const newPic = [...arrProVideos];
            setArrProVideos(newPic);
          }}>삭제</span>
        </div>

        <div className="videoDndWrap-row" style={{ marginTop: '10px' }}>
          <img className="videoDndWrap-img" style={{}} src={MyUtil._isNull(item.mv_rep_img) ? imgDefault : item.mv_rep_img} alt="logo image" />
        </div>

        <div className="videoDndWrap-row" style={{ marginTop: '3px' }}>
          <span className="videoDndWrap-imgTitle">{item.mv_nm}</span>
        </div>
      </div>
    </div>
  );
}

export default DragItem;