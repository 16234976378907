export default {

  // 디폴트 컬러
  mainColor:'#f08400',
  default_BgColor:'#fcfcfc',



  // 컴포넌트 컬러
  NavSide_BgColor:'#fcfcfc',
  notiRed:'#e83a56',
  notiGreen:'#21a621'
};
