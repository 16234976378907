// const BASE_URL = 'http://211.221.7.127:9184/'; // test (정훈 PC)
const BASE_URL = process.env.REACT_APP_API_URL; // 팀장님
// const BASE_URL = 'http://15.165.156.218:3909/'; // AWS

export default {

  // ***************** 서버 호출
  SERVER_URL: BASE_URL,
  API_URL: BASE_URL + 'gravity/',

  IMG_URL: BASE_URL + 'img/',   // 이미지 폴더
  PD_IMG_URL: BASE_URL + 'pd/',   // 상품 이미지 폴더


  S3_BUCKET: `gravity-s3bk`,
  // S3_BUCKET: `gravity-s3`,
  S3_REGION: `ap-northeast-2`,

  // ***************** 어싱크 키
  AS_KEY_LOGIN_INFO: 'as_key_login_info',



  // ***************** 서버 코드
  TYPE_BASIC: '1',
  TYPE_PREMIUM: '2',

  // ****************** 공통 사용 코드 
  APP_VER: '0.0.2',
  IS_LOG: true
};