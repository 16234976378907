import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactSortable } from "react-sortablejs";
import { useMutation } from 'react-query';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import * as MyUiFun from "../constants/MyUiFun";
import DragItem from '../component/DragItem';
import ModalVideoSelect from '../component/ModalVideoSelect';
import { useSelector } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import '../css/DragItem.css';

// id 필수임.
interface ItemType {
  id: number;
  mv_no: string;
  mv_nm: string;
  mv_rep_img: string;
  mv_url: string;
}


const ProgramsVideo = ({ location }: any) => {
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [branchName, setBranchName] = useState(MyUtil._isNull(location?.state?.title) ? '' : location?.state?.title);
  const [isVideoIuModalOpen, setIsVideoIuModalOpen] = useState(false);
  const [arrProVideos, setArrProVideos] = useState<ItemType[]>(MyUtil._isNull(location?.state?.arrProVideos) ? [] : location?.state?.arrProVideos);
  const [loading, setLoading] = useState<boolean>(false);
  const pro_no = MyUtil._isNull(location?.state?.pro_no) ? '' : location?.state?.pro_no;
  const isMobile = MyUtil.isMobile;


  useEffect(() => { }, []);



  const { mutate: m_mng_pro_m_i } = useMutation(ServerApi.m_mng_pro_m_i, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pro_m_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pro_m_i', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 완료되었습니다!"});
        history.push({ pathname: '/ProgramsItem', state: { korName: '프로그램' } });
      } else {
        MyUtil._alertMsg('m_mng_pro_m_i', data);
      };
    }
  });


  const ModalVideoCb = useCallback((isOk, jData) => {
    setIsVideoIuModalOpen(false);
    if (isOk) {
      const newArr = [...arrProVideos];
      newArr.push(jData);
      setArrProVideos(newArr);
    }
  }, [arrProVideos]);





  return (
    <div className="container">
      <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
        <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
        <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

        <div className='b-empty-row'></div>

        {
          isMobile && <div style={{ width: '530px', height: '15px' }}></div>
        }


        <div className="titleBtn-Con" onClick={() => { setIsVideoIuModalOpen(true); }}>
          <span className='titleBtn-text'>영상 추가</span>
        </div>

        <div className="titleBtn-Con" onClick={() => {
          if (MyUtil._isNull(branchName)) { return Modal.warning({ content: '이름을 입력해주세요.'}); };
          if (MyUtil._isNull(arrProVideos)) { return Modal.warning({ content: '영상을 추가해주세요.'}); };
          m_mng_pro_m_i({ pro_m_nm: branchName, pro_no: pro_no, array: arrProVideos ,center_no: rxLoginInfo.center_no });
        }}>
          <span className='titleBtn-text'>저장</span>
        </div>
      </div>


      <div className="container-wrap">
        <div className="modalIpWrap" style={{ marginBottom: '30px' }}>
          <span className="programAddTitle">제 목</span>
          <input type="text" className="programAddContents" required placeholder="입력해주세요"
            value={branchName} onChange={(evt) => setBranchName(evt.target.value)}></input>
        </div>
      </div>


      <div className="container-wrap">
        <ReactSortable group="groupName" swap={true} direction={'horizontal'} animation={50} list={arrProVideos} setList={setArrProVideos} handle={".handle"} style={{ display: 'contents' }}
          onChoose={(chooseData: any) => { MyUiFun.ClassAdd(chooseData.item, '.dndItemWrap-in', 'dndItemWrap-in dndItemWrap-select'); }}
          onUnchoose={(chooseData: any) => { MyUiFun.ClassDel(chooseData.item, '.dndItemWrap-in', 'dndItemWrap-select'); }}
          onEnd={(updateData: any) => {
            // console.log('이동된 인덱스 : ' + updateData.newIndex);
            // console.log('변경할 UID : ' + updateData.item.querySelector('.dndItem-key').textContent);
          }}>
          {!MyUtil._isNull(arrProVideos) && arrProVideos?.map((item, idx) => (
            <DragItem key={idx} item={item} idx={idx} arrProVideos={arrProVideos} setArrProVideos={setArrProVideos} />
          ))}

        </ReactSortable>
      </div >


      {
        isVideoIuModalOpen && (
          <ModalVideoSelect ModalCb={ModalVideoCb} isModalOpen={isVideoIuModalOpen} />
        )
      }
    </div>
  )
};

export default ProgramsVideo;
