import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import imgDefault from "../img/temp_pet.png";
import Config from '../constants/Config';
import '../css/BarabomTest.css';



const ProgramVideo = ({ location }: any) => {
  const isMobile = (window.innerWidth < 570);
  const history = useHistory();

  useEffect(() => { }, []);



  return (
    <div className="container">
      <div className="container-wrap">
        {
          ['테스트 테스트테스트 테스트테스트 테스트테스트 테스트테스트 테스트테스트 테스트테스트 테스트테스트 테스트테스트 테스트', 'Shoulder press series', 'Chest fly series', 'Shoulder raise series', 'Leg lift series'].map((item, idx) => (
            <div key={idx} className={isMobile ? 'bt-listWrap b-width-100' : 'bt-listWrap b-width-25'} onClick={() => { history.push('BarabomTest'); }}>
              <img className='bt-listImage' src={imgDefault} alt="level test title image" />

              <div className='bt-listTitleWrap'>
                <span className='bt-listTitleWrapText'>{item}</span>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
};

export default ProgramVideo;
