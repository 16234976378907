import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import icSearch from '../img/ic_search.png';
import Colors from '../constants/Colors';
import ModalBranchIU from "../component/ModalBranchIU";
import Loader from '../component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";

const BranchManage = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [searchText, setSearchText] = useState<string>('');
  const [arrCenter, setArrCenter] = useState<null | []>([]);
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState<any>(null);
  const isMobile = MyUtil.isMobile;


  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
  }, []);



  const m_mng_center_query = useQuery([arrCenter, searchText], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_center({ center_nm: searchText }); }, {
    retry: 1, enabled: arrCenter === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center', result);

      if (isOk) {
        setArrCenter(data.array);
      } else {
        setArrCenter(null);
        MyUtil._alertMsg('m_mng_center', data);
      };
    },
  });



  const { mutate: m_mng_center_i } = useMutation(ServerApi.m_mng_center_i, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_i', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_i', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 등록이 완료되었습니다!"});
        // ---- 리스트 재조회 
        // setArrCenter([]); 
      } else {
        MyUtil._alertMsg('m_mng_center_i', data);
      };
    }
  });



  const { mutate: m_mng_center_u } = useMutation(ServerApi.m_mng_center_u, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_u', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_u', result);

      if (isOk) {
        Modal.info({ content: "정상적으로 수정이 완료되었습니다!"});
        // ---- 리스트 재조회 
        setArrCenter([]);
      } else {
        MyUtil._alertMsg('m_mng_center_u', data);
      };
    }
  });



  const ModalCb = useCallback((isOk, jData) => {
    setIsClassModalOpen(false);
    if (isOk) {
      const centerNm = { center_nm: searchText };
      const mergeJson = { ...jData, ...centerNm };

      if (MyUtil._isNull(mergeJson.center_no)) {
        m_mng_center_i(mergeJson);
      } else {
        m_mng_center_u(mergeJson);
      };
    };
  }, [searchText]);


  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              <div className="titleBtn-Con" onClick={() => { setUpdateData(null); setIsClassModalOpen(true); }}>
                <span className='titleBtn-text'>신규지점 등록</span>
              </div>

              <div className="searchBox-Con">
                <input type="text" className="searchBox-IpWrap" placeholder="지점 검색"
                  value={searchText} onChange={(evt) => setSearchText(evt.target.value)}></input>

                <img className='searchBox-ImgBtn' src={icSearch} alt="검색 버튼" onClick={() => { setArrCenter([]); }} />
              </div>
            </div>

            <div className="container-wrap">
              {

                !MyUtil._isNull(arrCenter) && arrCenter?.map((item: any, idx: number) => (
                  <div key={idx} className={isMobile ? 'itembox01 b-width-100' : 'itembox01 b-width-25'} >
                    <span className='itembox01-topNotiWrap' style={{ backgroundColor: item.pay_state_type === '2' ? Colors.notiGreen : Colors.notiRed }}>{item.pay_state_type === '2' ? '정상' : '연체'}</span>

                    <span className="itembox01-title">{item.center_nm}</span>

                    <span className="itembox01-sub01">{`담당자 : ${item.p_nm}`}</span>
                    <span className="itembox01-sub01">{`전화번호 : ${MyUtil.NumFormat(item.p_tel)}`}</span>
                    <span className="itembox01-sub01">{`이메일 : ${item.p_email}`}</span>
                    <span className="itembox01-sub01">{`요금제 : ${item.pay_type_nm}`}</span>
                    <span className="itembox01-sub01">{`시작일 : ${item.begin_date}`}</span>
                    <span className="itembox01-sub01">{`결제일 : 매월 ${item.cost_day}일`}</span>
                    <span className="itembox01-sub01">{`연체횟수 : 0000회`}</span>

                    <div className='itembox01-bottomBtnWrap'>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '8px', color: '#5e5ae6', borderColor: '#5e5ae6' }} onClick={() => { setUpdateData(item); setIsClassModalOpen(true); }}>{'지점수정'}</span>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '0px', color: '#cc8408', borderColor: '#cc8408' }} onClick={() => {
                        history.push({ pathname: '/BranchProgramsCalendar', state: { branchInfo: item } });
                      }}>{'패키지 등록'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }

      {
        isClassModalOpen && (
          <ModalBranchIU ModalCb={ModalCb} isModalOpen={isClassModalOpen} updateData={updateData} />
        )
      }
    </div>
  )

};

export default BranchManage;
