import Globalstyles from "./constants/GlobalStyles";
import './css/Basic.css';
import './css/Component.css';
import Router from "./component/Router";

// ##### 리덕스 관련 ######
import rootReducer from "./component/redux/rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
const store = configureStore({
  reducer: rootReducer,
  // middleware: [...middlewares],
  devTools: false
});
// ##### 리덕스 관련 END



function App() {
  return (
    <Provider store={store}>
      <Globalstyles />
      <Router />
    </Provider>
  );
}

export default App;