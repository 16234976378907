import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
    ${reset};
    a{
        text-decoration:none;
        color:inherit;
    }
    *{
        box-sizing:boerder-box;
        margin: 0;
        padding: 0; 
    }
    body{
        font-family: 'NanumBarunGothic';
        font-size: 14px;
        background-color:#ffffff;
        overflow: auto;
        color: #292929
    }
    input:focus {
        outline: none;
    }
    input:-webkit-autofill {
        box-shadow: 0 0 0 1000px white inset;
    } 
    
    input::-webkit-input-placeholder {
        color: #b0b0b0;
    }

    input:-ms-input-placeholder {
        color: #b0b0b0;
    }

    textarea::-webkit-input-placeholder {
        color: #b0b0b0;
    }

    textarea:-ms-input-placeholder {
        color: #b0b0b0;
    }

    .duration02 {
        transition-duration: 0.2s;
    }
`

export default GlobalStyles;