import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import Loader from '../component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";

const ProgramsItem = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = MyUtil.isMobile;
  const [arrProgram, setArrProgram] = useState<null | []>([]);
  const history = useHistory();

  useEffect(() => { MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, false); }, []);



  const m_mng_pro_m_query = useQuery([arrProgram], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_pro_m({ center_no: rxLoginInfo.center_no, c_day: '' }); }, {
    retry: 1, enabled: arrProgram === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pro_m', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pro_m', result);

      if (isOk) {
        setArrProgram(data.array);
      } else {
        setArrProgram(null);
        MyUtil._alertMsg('m_mng_pro_m', data);
      };
    },
  });


  const { mutate: m_mng_pro_m_d } = useMutation(ServerApi.m_mng_pro_m_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pro_m_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pro_m_d', result);

      if (isOk) {
        Modal.warning({ content: "정상적으로 삭제되었습니다!" });
        setArrProgram([]);   // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_pro_m_d', data);
      };
    }
  });



  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
              <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              <div className="titleBtn-Con" onClick={() => { history.push({ pathname: 'ProgramsVideo', state: { korName: '프로그램 추가', title: '', arrProVideos: [], subName: !MyUtil._isNull(location?.state?.subName) ? location.state.subName : '', pro_no: '' } }); }}>
                <span className='titleBtn-text'>프로그램 추가</span>
              </div>
            </div>


            <div className="container-wrap">
              {
                !MyUtil._isNull(arrProgram) && arrProgram?.map((item: any, idx) => (
                  <div key={idx} className={isMobile ? 'itembox02 b-width-100' : 'itembox02 b-width-25'}>

                    <span className="itembox02-title02" style={{ backgroundColor: '#f2bb3d' }}>{item.pro_m_nm}</span>

                    <div className='itembox02-scrollview'>
                      {
                        !MyUtil._isNull(item.in_array) && item?.in_array.map((subItem: any, subIdx: number) => (
                          <div key={subIdx} className='itembox02-sub02-wrap'>
                            <span className="itembox01-sub02">{(subIdx + 1)}. {subItem.mv_nm}</span>
                            {/* <span className="itembox01-sub03">{'(동영상 2개)'}</span> */}
                          </div>
                        ))
                      }
                    </div>


                    <div className='itembox02-bottomBtnWrap'>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '8px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }}
                        onClick={() => {
                          Modal.confirm({
                            okText: "확인", cancelText: "취소",
                            title: '프로그램 삭제',
                            content: '프로그램을 삭제할까요?',
                            onOk() { m_mng_pro_m_d({ pro_no: item.pro_no }); },
                            onCancel() { },
                          });
                        }}>{'삭제'}</span>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '0px', color: '#0fb500', borderColor: '#0fb500', backgroundColor: '#ffffff' }}
                        onClick={() => { history.push({ pathname: 'ProgramsVideo', state: { korName: '프로그램 수정', title: item.pro_m_nm, arrProVideos: item.in_array, subName: !MyUtil._isNull(location?.state?.subName) ? location.state.subName : '', pro_no: item.pro_no } }); }}>{'수정'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }
    </div>
  )

};

export default ProgramsItem;
