import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import logoOriginal from '../img/logo_original.png';
import allActions from "../component/redux/allActions";
import CST, { STORAGE_S } from '../constants/Constants';

const Main = ({ }) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [userid, setUserid] = useState('');
  const [userPw, setUserPw] = useState('');


  const { mutate: loginMutate } = useMutation(ServerApi.m_mng_login, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_login', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_login', result);

      if (isOk) {
        dispatch(allActions.setRxLoginInfo(result.data.resObject));

        setTimeout(() => {
          sessionStorage.setItem(STORAGE_S.LOGIN_INFO, JSON.stringify(result.data.resObject));
          history.push({ pathname: '/' + 'BranchManage', state: { korName: '지점 관리' } });
        }, 500);
      } else {
        sessionStorage.setItem(STORAGE_S.LOGIN_INFO, "");
        dispatch(allActions.logOut());
        MyUtil._alertMsg('m_mng_login', result.data.resObject);
      };
    }
  });


  return (
    <div className="container bgMain">
      <div className="container-login">

        <div className="loginBox b-blur40">
          <img style={{ width: '170px' }} src={logoOriginal} alt="logo image" />
          <div className="loginIpBox">
            <div className="loginIpWrap">
              <span className="loginIpTitle">ID</span>
              <input type="text" className="loginIpId" required
                value={userid} onChange={(evt) => setUserid(evt.target.value)}></input>
            </div>

            <div style={{ width: '90%', marginLeft: '5%', height: '1px', backgroundColor: '#dbdbdb', margin: '5px 0 5px 5%' }}></div>

            <div className="loginIpWrap">
              <span className="loginIpTitle">PW</span>
              <input type="password" className="loginIpId" required
                value={userPw} onChange={(evt) => setUserPw(evt.target.value)}></input>
            </div>
          </div>


          <div className="loginBtn" onClick={() => {
            if (MyUtil._isNull(userid)) { return Modal.warning({ content: '아이디를 입력하세요'}); };
            if (MyUtil._isNull(userPw)) { return Modal.warning({ content: '비밀번호를 입력하세요'}); };
            loginMutate({ userid: userid, password: userPw });
          }}>
            <span className="loginBtnTxt">Login</span>
          </div>
        </div>
      </div>
    </div>
  )

};

export default Main;
