import * as MyUtil from "../constants/MyUtil";
import imgDefault from "../img/bg_gym.jpg";
import '../css/DragItem.css';


const DragProItem = ({ item, idx, arrProVideos, setArrProVideos }: any) => {

  return (
    <div className={MyUtil.isMobile ? 'dndItemWrap  b-width-100' : 'dndItemWrap b-width-200p'}>
      <div className='videoDndWrap-in'>
          <span className="itembox02-title02" style={{ backgroundColor: '#f2bb3d' }}>{item.pro_m_nm}</span>

          <div className='itembox02-scrollview'>
            {
              !MyUtil._isNull(item.in_array) && item?.in_array.map((subItem: any, subIdx: number) => (
                <div key={subIdx} className='itembox02-sub02-wrap'>
                  <span className="itembox01-sub02">{(subIdx + 1)}. {subItem.mv_nm}</span>
                  {/* <span className="itembox01-sub03">{'(동영상 2개)'}</span> */}
                </div>
              ))
            }
          </div>


          <div className='itembox02-bottomBtnWrap'>
            <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '8px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }}
              onClick={() => {
                arrProVideos.splice(idx, 1);
                const newPic = [...arrProVideos];
                setArrProVideos(newPic);
              }}>{'삭제'}</span>
          </div>
        </div>


    </div>
  );
}

export default DragProItem;