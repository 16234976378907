import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import * as MyUtil from "../constants/MyUtil";
import * as SG from '../constants/Signature';
import '../css/CssOverride.css';
import Config from '../constants/Config';


const ModalBranchIU = ({ ModalCb, isModalOpen, updateData, isAdmin = true }: SG.ModalProps) => {
  const isMobile = (window.innerWidth < 570);
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '400px';
  const [centerNo, setCenterNo] = useState('');
  const [branchName, setBranchName] = useState('');
  const [branchPw, setBranchPw] = useState('');
  const [exTime, setExTime] = useState('0');
  const [restTime, setRestTime] = useState('0');
  const [managerName, setManagerName] = useState('');
  const [managerPhone, setManagerPhone] = useState('');
  const [managerEmail, setManagerEmail] = useState('');
  const [payAmount, setPayAmount] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [startTime, setStartTime] = useState('');
  const [costDay, setCostDay] = useState('1');
  const [payType, setPayType] = useState<any>({ cd: '1', nm: '베이직' });


  useEffect(() => {
    if (!MyUtil._isNull(updateData)) {
      if (isAdmin) {
        setBranchName(updateData.center_nm);
        setBranchPw(updateData.center_pw);
        setManagerName(updateData.p_nm);
        setManagerPhone(updateData.p_tel);
        setManagerEmail(updateData.p_email);
        setPayAmount(updateData.cost);
        setCostDay(updateData.cost_day);
        setStartTime(updateData.begin_date);
        setBranchCode(updateData.center_cd);
      };
      setPayType({ cd: updateData?.pay_type === Config.TYPE_BASIC ? Config.TYPE_BASIC : Config.TYPE_PREMIUM, nm: updateData.pay_type_nm });
      setCenterNo(updateData.center_no);
      setExTime(updateData.ex_time);
      setRestTime(updateData.br_time);

    } else {
      setBranchName('');
      setBranchPw('');
      setManagerName('');
      setManagerPhone('');
      setManagerEmail('');
      setPayAmount('');
      setCostDay('');
      setStartTime('');
      setBranchCode('');
      setCenterNo('');
      setPayType({ cd: '1', nm: '베이직' });
    };
  }, []);


  const getDays = () => {
    const result = [];
    for (let i = 1; i < 32; i++) {
      result.push(<option value={i + ''}>{i + ''}</option>);
    };
    return result;
  };



  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title={MyUtil._isNull(updateData) ? "신규지점 등록" : "지점 수정"}
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>
        {
          isAdmin ? (
            <>
              <div className="modalIpWrap" style={{ marginTop: '0px' }}>
                <span className="modalIpTitle">지점 이름</span>
                <input type="text" className="modalIp" required placeholder="입력해주세요"
                  value={branchName} onChange={(evt) => setBranchName(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">담당자 이름</span>
                <input type="text" className="modalIp" required placeholder="입력해주세요"
                  value={managerName} onChange={(evt) => setManagerName(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">담당자 전화</span>
                <input type="text" className="modalIp" required placeholder="번호를 입력해주세요"
                  value={managerPhone} onChange={(evt) => setManagerPhone(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">담당자 메일</span>
                <input type="text" className="modalIp" required placeholder="이메일을 입력해주세요"
                  value={managerEmail} onChange={(evt) => setManagerEmail(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">운동 시간</span>
                <input type="number" className="modalIp" required placeholder="입력해주세요"
                  value={exTime} onChange={(evt) => setExTime(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">쉬는 시간</span>
                <input type="number" className="modalIp" required placeholder="입력해주세요"
                  value={restTime} onChange={(evt) => setRestTime(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">요금제</span>
                <select name="" id="" className="modalIp solidSelect" onChange={({ target }) => { setPayType({ cd: target.value, nm: target.textContent }); }}>
                  <option value={'1'} selected={MyUtil._isNull(updateData?.pay_type) ? true : updateData?.pay_type === Config.TYPE_BASIC ? true : false}>{'베이직'}</option>
                  <option value={'2'} selected={updateData?.pay_type === Config.TYPE_PREMIUM ? true : false}>{'프리미엄'}</option>
                </select>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">결제 비용</span>
                <input type="text" className="modalIp" required placeholder="결제 비용을 입력해주세요"
                  value={payAmount} onChange={(evt) => setPayAmount(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">결제일</span>
                <select name="" id="" className="modalIp solidSelect" onChange={({ target }) => { setCostDay(`${target.value}`); }}>
                  {getDays()}
                </select>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">시작일</span>
                <input type="date" className="modalIp solidSelect" required placeholder="입력해주세요" value={startTime} onChange={({ target }) => { setStartTime(target.value); }}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">지점 코드</span>
                <input type="text" className="modalIp" required placeholder="입력해주세요"
                  value={branchCode} onChange={(evt) => setBranchCode(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">비밀 번호</span>
                <input type="text" className="modalIp" required placeholder="입력해주세요"
                  value={branchPw} onChange={(evt) => setBranchPw(evt.target.value)}></input>
              </div>

            </>
          ) : (
            <>
              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">운동 시간</span>
                <input type="number" className="modalIp" required placeholder="입력해주세요"
                  value={exTime} onChange={(evt) => setExTime(evt.target.value)}></input>
              </div>

              <div className="modalIpWrap" style={{ marginTop: '5px' }}>
                <span className="modalIpTitle">쉬는 시간</span>
                <input type="number" className="modalIp" required placeholder="입력해주세요"
                  value={restTime} onChange={(evt) => setRestTime(evt.target.value)}></input>
              </div>

            </>
          )
        }

        <div className="modalSaveBtn" onClick={() => {
          if (isAdmin) {
            if (MyUtil._isNull(branchName)) { return Modal.warning({ content: '지점명을 입력해주세요.' }); };
            if (MyUtil._isNull(branchPw)) { return Modal.warning({ content: '비밀번호를 입력해주세요.' }); };
            if (MyUtil._isNull(managerName)) { return Modal.warning({ content: '담당자를 입력해주세요.' }); };
            if (MyUtil._isNull(managerPhone)) { return Modal.warning({ content: '전화번호를 입력해주세요.' }); };
            if (MyUtil._isNull(managerEmail)) { return Modal.warning({ content: '메일을 입력해주세요.' }); };
            if (MyUtil._isNull(payAmount)) { return Modal.warning({ content: '비용을 입력해주세요.' }); };
            if (MyUtil._isNull(costDay)) { return Modal.warning({ content: '결제일을 입력해주세요.' }); };
            if (MyUtil._isNull(startTime)) { return Modal.warning({ content: '시작일을 입력해주세요.' }); };
            if (MyUtil._isNull(branchCode)) { return Modal.warning({ content: '지점 코드를 입력해주세요.' }); };
          };

          ModalCb(true, {
            center_no: centerNo,
            center_nm: branchName,
            center_pw: branchPw,
            p_nm: managerName,
            p_tel: managerPhone,
            p_email: managerEmail,
            cost: payAmount,
            cost_day: costDay,
            begin_date: startTime,
            center_cd: branchCode,
            ex_time: exTime,
            br_time: restTime,
            pay_type: payType.cd
          });
        }}>
          <span className="loginBtnTxt">저 장</span>
        </div>
      </div>
    </Modal >
  );
}
export default ModalBranchIU;