import React, { useState, useCallback } from "react";
import styled from "styled-components";
import imgDefault from "../img/bg_regImage.jpg";

const BannerItem2 = ({ width = "400px", height = "300px", marginTop = "15px", marginRight = '0px', item, setImgFile }: any) => {

  const initBgImg = (item.url === null || item.url === "") ? (`url(${imgDefault})`) : (`url(${item.url})`);
  const initAnchor = item.anchor === null || typeof (item.anchor) === 'undefined' ? "" : item.anchor;
  const uid = item.uid === null || typeof (item.uid) === 'undefined' ? "" : item.uid;

  const [anchor, setAnchor] = useState(initAnchor);
  const [bgStyle, setBgStyle] = useState({ backgroundImage: initBgImg, width, height });


  const _hcImage = useCallback((e) => {
    let reader = new FileReader();
    const file = e.target.files[0];

    // 파일을 읽어서 로드가 되면 프리뷰 합니다.
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      const newStyle = { backgroundImage: `url(${reader.result})`, width, height };
      setImgFile(file);
      setBgStyle(newStyle);
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <DivWrap style={{ marginTop, width, marginRight }}>
      <span onClick={(e: any) => { e.currentTarget.childNodes[0].click() }}>
        <input type="file" accept="image/*" onChange={(e) => _hcImage(e)} style={{ display: 'none' }} />  {/* input 숨김 */}
        <DivImgBg style={{ ...bgStyle }}></DivImgBg>
      </span>
    </DivWrap>
  );
}


const DivWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const DivImgBg = styled.div`
  display: flex;
  height: 270px;
  margin-top: 15px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-width: 1px;
  border-color: #d8d8d8;
  border-style: solid;
`;

const IpUrl = styled.input`
  height: 30px;
  font-size: 14px;
  margin: 10px 0px 0px 0px;
  font-weight: lighter;
  box-sizing: border-box;
`;
export default BannerItem2;