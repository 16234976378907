import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Colors from '../constants/Colors';
import imgLogoMain from '../img/logo_main_wh.png';
import { faBuilding, faBoxesPacking, faMoneyBill, faVideo, faFlag, faCreditCard, faBaseball, faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from '../constants/Config';
import { RootState } from '../component/redux/rootReducer';
import * as MyUtil from "../constants/MyUtil";
import { useSelector, useDispatch } from 'react-redux';
import allActions from "../component/redux/allActions";

const NavSide = ({ location }: any) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });

  useEffect(() => {
    // MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
  }, []);


  const goPage = useCallback((getPageName, getKorName) => {
    // const bodyTag = document.querySelector('body');
    // bodyTag?.classList.remove('noScroll');
    history.push({ pathname: '/' + getPageName, state: { korName: getKorName } });
  }, []);

  // (location?.pathname === '/Login') && return (<></>)
  // console.log('location?.pathname : ' + location?.pathname)

  return (
    <div className="sideBox" style={{ backgroundColor: Colors.NavSide_BgColor }}>
      <div className='b-blur50-bk'>
        <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', paddingTop: '18px', marginBottom: '25px' }}>
          <img style={{ width: '80px' }} src={imgLogoMain} alt="logo image" />
        </div>


        <div className='scrollY-box'>
          {
            rxLoginInfo?.c_gb === 'a' ? (
              <>
                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/BranchManage' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('BranchManage', '지점 관리'); }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faBuilding} />
                  <span className="NavSide-logoTxt">지점 관리</span>
                </div>

                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/TutorialVideo' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('TutorialVideo', '튜토리얼'); }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faFlag} />
                  <span className="NavSide-logoTxt">튜토리얼 관리</span>
                </div>

                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/VideoCategory' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('VideoCategory', '영상 카테고리'); }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faVideo} />
                  <span className="NavSide-logoTxt">영상 관리</span>
                </div>
              </>
            ) : (
              <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/BranchManage' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { history.push({ pathname: '/BranchProgramsCalendar', state: { branchInfo: rxLoginInfo } }); }}>
                <FontAwesomeIcon className='NavSide-img' icon={faBuilding} />
                <span className="NavSide-logoTxt">일자별 패키지 등록</span>
              </div>
            )
          }

          {
            (rxLoginInfo?.c_gb === 'a' || rxLoginInfo?.pay_type === Config.TYPE_PREMIUM) && (
              <>
                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/ProgramsItem' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('ProgramsItem', '프로그램'); }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faProjectDiagram} />
                  <span className="NavSide-logoTxt">프로그램 관리</span>
                </div>

                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/PackagesItem' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('PackagesItem', '프로그램 패키지'); }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faBoxesPacking} />
                  <span className="NavSide-logoTxt">패키지 관리</span>
                </div>
              </>
            )
          }

          {
            rxLoginInfo?.c_gb === 'a' ? (
              <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/Sales' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('Sales', '매출 관리'); }}>
                <FontAwesomeIcon className='NavSide-img' icon={faMoneyBill} />
                <span className="NavSide-logoTxt">매출 관리</span>
              </div>
            ) : (
              <>
                {
                  rxLoginInfo?.pay_type === Config.TYPE_PREMIUM && (
                    <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/SABPayList' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('SABPayList', '결제 관리'); }}>
                      <FontAwesomeIcon className='NavSide-img' icon={faCreditCard} />
                      <span className="NavSide-logoTxt">결제 관리</span>
                    </div>
                  )
                }

                <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/SBDayProgram' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => {
                  const sideBox = document.querySelector('.sideBox');
                  const appContainer = document.querySelector('.appContainer');
                  const NavTopBar = document.querySelector('.NavTopBar');

                  sideBox?.classList.remove('sideBox-open');
                  appContainer?.classList.remove('appContainer-sideOpen');
                  NavTopBar?.classList.remove('NavTopBar-sideOpen');

                  goPage('SBDayProgram', '');
                }}>
                  <FontAwesomeIcon className='NavSide-img' icon={faBaseball} />
                  <span className="NavSide-logoTxt">운동기록으로 이동</span>
                </div>
              </>

            )
          }



          {/* <div className='NavSide-box' style={{ backgroundColor: location?.pathname === '/Sales' ? 'rgba( 255, 255, 255, 0.20)' : 'rgba( 255, 255, 255, 0)' }} onClick={() => { goPage('BarabomTestList', '매출 관리'); }}>
            <FontAwesomeIcon className='NavSide-img' icon={faSort} />
            <span className="NavSide-logoTxt">...</span>
          </div> */}




        </div>
      </div>
    </div>
  );
}

export default NavSide;