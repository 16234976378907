import { Modal } from 'antd';
import * as MyUtil from "../constants/MyUtil";
import logoArrowOnly from '../img/logo_arrow_only_wh.png';
import '../css/CssOverride.css';



const ModalSBDayProgram = ({ ModalCb, isModalOpen, history, selectProgram }: any) => {
  const modalWidth = MyUtil.isMobile ? 'calc(100% - 40px)' : '400px';

  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title={'영상 목록'}
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>
        {
          selectProgram?.in_array?.map((item:any, idx:any) => (
            <div key={idx} className={'sbday-wrap'}>
              <span className="sbday-text">{item.mv_nm}</span>
            </div>
          ))
        }
        <div className="modalSaveBtn" onClick={() => { history.push({ pathname: '/SBVideoPlay', state: {selectProgram:selectProgram} }) }}>
          <img style={{ width: '24px', height: '24px', marginRight: '8px' }} src={logoArrowOnly} alt="logo image" />

          <span className="loginBtnTxt">DO GRAVITY!</span>
        </div>
      </div>
    </Modal>
  );
}
export default ModalSBDayProgram;