import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import logoOriginal from '../img/logo_original.png';
import Config from '../constants/Config';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import CST, { STORAGE_S } from '../constants/Constants';


// ROOT : 총 관리자
// ADMIN : 지점 관리자
// USER : 지점 사용자
const SBLogin = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const [userid, setUserid] = useState('');
  const [mIsAdmin, setMIsAdmin] = useState(false);
  const [deviceUid, setDeviceUid] = useState('');

  useEffect(() => {

    // *** 웹뷰 통신 리스너
    document.addEventListener("message", (evt: any) => { setDeviceUid(evt.data); });


    if (MyUtil._isNull(rxLoginInfo)) {
      setMIsAdmin(false);
    } else {
      if (rxLoginInfo.pay_type === Config.TYPE_PREMIUM) {
        setMIsAdmin(true);
      } else {
        Modal.warning({ content: '프리미엄 회원만 사용가능합니다.'});
        dispatch(allActions.logOut());

        setTimeout(() => {
          return history.push({ pathname: '/SBDayProgram' });
        }, 500);
      };
    };
  }, []);


  const { mutate: m_mng_center_check } = useMutation(ServerApi.m_mng_center_check, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_check', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_check', result);

      if (isOk) {
        setTimeout(() => {
          sessionStorage.setItem(STORAGE_S.LOGIN_INFO, JSON.stringify(result.data.resObject));
          history.push({ pathname: '/BranchProgramsCalendar', state: { branchInfo: rxLoginInfo } });
        }, 500);
      } else {
        sessionStorage.setItem(STORAGE_S.LOGIN_INFO, "");
        dispatch(allActions.logOut());
        MyUtil._alertMsg('m_mng_center', data);
      };
    }
  });


  const { mutate: loginMutate } = useMutation(ServerApi.m_mng_center_login, {
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_center_login', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_center_login', result);

      if (isOk) {
        dispatch(allActions.setRxLoginInfo(result.data.resObject));

        setTimeout(() => {
          sessionStorage.setItem(STORAGE_S.LOGIN_INFO, JSON.stringify(result.data.resObject));
          history.push({ pathname: '/SBDayProgram', state: {} });
        }, 500);

      } else {
        sessionStorage.setItem(STORAGE_S.LOGIN_INFO, "");
        dispatch(allActions.logOut());
        MyUtil._alertMsg('m_mng_center_login', result.data.resObject);
      };
    }
  });



  return (
    <div className="container bgMain">
      <div className="container-login">

        <div className="loginBox b-blur40">
          <img style={{ width: '170px' }} src={logoOriginal} alt="logo image" />
          <span className="loginLogo">{mIsAdmin ? '관리자 비밀번호를 입력하세요' : '지점 코드를 입력하세요'}</span>

          <div className="loginIpBox">
            <div className="loginIpWrap">
              <span className="loginIpTitle">{mIsAdmin ? '비번' : '코드'}</span>
              <input type="text" className="loginIpId" required
                value={userid} onChange={(evt) => setUserid(evt.target.value)}></input>
            </div>
          </div>


          <div className="loginBtn" onClick={() => {
            if (!mIsAdmin) {
              if (MyUtil._isNull(userid)) { return Modal.warning({ content: '지점 코드를 입력하세요'}); };
              loginMutate({ center_cd: userid });
            } else {
              if (MyUtil._isNull(userid)) { return Modal.warning({ content: '지점 비밀번호를 입력하세요'}); };
              m_mng_center_check({ center_cd: rxLoginInfo.center_cd, center_pw: userid });
            };
          }}>
            <span className="loginBtnTxt">Login</span>
          </div>
        </div>


        <span style={{ position: 'absolute', bottom: '10px', right: '10px', color: '#ffffff', fontSize: '13px' }}>{deviceUid}</span>
      </div>
    </div>
  )

};

export default SBLogin;