import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { QueryFunctionContext, useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";
import * as ServerApi from "../constants/ServerApi";
import ModalSBDayProgram from '../component/ModalSBDayProgram';
import * as MyUtil from "../constants/MyUtil";
import Loader from '../component/Loader';
import Sprintf from 'sprintf-js';
const sprintf = Sprintf.sprintf;

const SBDayProgramEx = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [isDpModal, setIsDpModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectProgram, setSelectProgram] = useState<any>(null);
  const [arrCalData, setArrCalData] = useState<any>([]);
  const [viewWeekNo, setViewWeekNo] = useState<any>({ weekNo: -1, startNo: 0, endNo: 0, maxWeekNo: 0 }); // #미니캘린더용 @뷰의 주차 @한주의 시작일 @한주의 끝일 @달의 최대주차
  const [selectDay, setSelectDay] = useState<any>({ day: null, fullDay: null });
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [arrProgram, setArrProgram] = useState<null | []>([]);


  useEffect(() => {
    MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);
    const today = new Date();
    // setSelectDay({ date: sprintf("%02d", 1, today.getDate()), fullDay: sprintf("%04d-%02d-%02d", today.getFullYear(), today.getMonth() + 1, today.getDate()) })
    initCalendar(today, true, true);
  }, []);


  const m_center_pro_m_query = useQuery([arrProgram, selectDay], (ctx: QueryFunctionContext) => { return ServerApi.m_center_pro_m({ center_no: rxLoginInfo.center_no, c_day: selectDay.fullDay }); }, {
    retry: 1, enabled: arrProgram === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_center_pro_m', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_center_pro_m', result);

      if (isOk) {
        const newArr: any = [...data.array];
        setArrProgram(newArr);
      } else {
        setArrProgram(null);
        MyUtil._alertMsg('m_center_pro_m', data);
      };
    },
  });


  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------

  const initCalendar = useCallback(async (getDate: any, isWeekStart: boolean, isReset: boolean) => {
    const calData = [];

    const firstDate = new Date(getDate.getFullYear(), getDate.getMonth(), 1);
    const lastDate = new Date(getDate.getFullYear(), getDate.getMonth() + 1, 0);
    const monthFirstDateDay = firstDate.getDay();

    // 첫달 시작 요일에 따라서 빈값 넣어줍니다.
    for (let emptyCnt = 0; emptyCnt < monthFirstDateDay; emptyCnt++) {
      MyUtil._consoleLog("empty");
      calData.push({ date: '', fullDay: '' });
    };


    for (let dayCnt = 1; dayCnt < lastDate.getDate() + 1; dayCnt++) {
      const fullDay = sprintf("%04d-%02d-%02d", getDate.getFullYear(), getDate.getMonth() + 1, dayCnt);
      MyUtil._consoleLog("day : " + dayCnt + " / fullDay : " + fullDay);
      var week = ['일', '월', '화', '수', '목', '금', '토'];
      var dayOfWeek = week[new Date(fullDay).getDay()];
      calData.push({ day: dayCnt + '', fullDay: fullDay, dayOfWeekKor: dayOfWeek });
    };



    const maxWeekNo = (Math.floor((calData.length - 1) / 7) + 1);
    MyUtil._consoleLog('maxWeekNo : ' + maxWeekNo);
    MyUtil._consoleLog('calData : ' + JSON.stringify(calData));


    // 최초 세팅 , 뷰 변경
    if (viewWeekNo.weekNo === -1 || isReset) {
      const fullDay = sprintf("%04d-%02d-%02d", getDate.getFullYear(), getDate.getMonth() + 1, getDate.getDate());
      SelectCalDay({ day: getDate.getDate(), fullDay: fullDay }, (monthFirstDateDay + getDate.getDate() - 1), { maxWeekNo }, false);

    } else {

      if (isWeekStart) {
        setViewWeekNo({ weekNo: 1, startNo: 0, endNo: 6, maxWeekNo });
      } else {
        const weekNo = maxWeekNo;
        const startNo = (weekNo - 1) * 7;
        const endNo = startNo + 6;
        setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo });
      };
    };


    setLoading(false);
    setArrCalData(calData);
  }, [viewWeekNo]);


  const SelectCalDay = useCallback(async (item, idx, getViewWeelNo, isLoading) => {
    const weekNo = Math.floor(idx / 7) + 1;
    const startNo = (weekNo - 1) * 7;
    const endNo = startNo + 6;

    MyUtil._consoleLog('weekNo : ' + weekNo);
    setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo: getViewWeelNo.maxWeekNo });
    setSelectDay({ day: item.day, fullDay: item.fullDay });
  }, []);


  const WeekPrev = useCallback(async (getViewDate) => {
    const weekNo = viewWeekNo.weekNo - 1;
    if (weekNo <= 0) {
      getViewDate.setMonth(getViewDate.getMonth() - 1);
      const newDate = new Date(getViewDate);
      setViewDate(newDate);
      initCalendar(newDate, false, false);

    } else {
      const startNo = (weekNo - 1) * 7;
      const endNo = startNo + 6;
      setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo: viewWeekNo.maxWeekNo });
    };
  }, [viewWeekNo]);


  const WeekNext = useCallback(async (getViewDate) => {
    const weekNo = viewWeekNo.weekNo + 1;
    MyUtil._consoleLog("weekNo : " + weekNo + " / viewWeekNo.maxWeekNo : " + (viewWeekNo.maxWeekNo));

    if (weekNo > viewWeekNo.maxWeekNo) {
      getViewDate.setMonth(getViewDate.getMonth() + 1);
      const newDate = new Date(getViewDate);
      setViewDate(newDate);
      initCalendar(newDate, true, false);

    } else {
      const startNo = (weekNo - 1) * 7;
      const endNo = startNo + 6;
      setViewWeekNo({ weekNo, startNo, endNo, maxWeekNo: viewWeekNo.maxWeekNo });
    };
  }, [viewWeekNo]);

  const renderDayItem = (idx: number, item: any) => {
    return (
      MyUtil._isNull(item.day) ? (
        <div key={idx} className={`sbdayprogram-calBox`} style={{ borderColor: '#000000',minWidth:'1px',padding:'0px',margin:'0px',overflow:'hidden' }}></div>
      ) : (
        <div key={idx} className={`sbdayprogram-calBox ${selectDay.fullDay === item.fullDay ? 'sbdayprogram-calBox-on' : 'sbdayprogram-calBox-off'}`} onClick={() => {
          setSelectDay({ day: item.day, fullDay: item.fullDay });
        }}>
          <span style={{ fontSize: '14px' }}>{item.dayOfWeekKor}</span>
          <span style={{ marginTop: '7px', fontWeight: 'bold' }}>{item.day}</span>
        </div>
      )
    )
  }

  //  달력 ---------------------------------------------------------------------------------------------------------------------------------------------------


  const ModalCb = useCallback((isOk, jData) => {
    setIsDpModal(false);
  }, []);






  return (
    <div className="container-zero" style={{ backgroundColor: '#000000' }}>
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-row" style={{ marginTop: '20px', justifyContent: 'center' }}>
              <span className='sbdayprogram-dayTiTleText'>{viewDate.getFullYear()}년 {viewDate.getMonth() + 1}월</span>
            </div>

            <div className="container-row" style={{ marginTop: '25px', justifyContent: 'center', alignItems: 'center' }}>

              <span className='sbdayprogram-calBtn' onClick={() => { WeekPrev(viewDate) }}>{`< 이전`}</span>

              {
                arrCalData.map((item: any, idx: number) => ((idx >= viewWeekNo.startNo && idx <= viewWeekNo.endNo) && (
                  renderDayItem(idx, item)
                )))
              }

              <span className='sbdayprogram-calBtn' onClick={() => { WeekNext(viewDate) }}>{`다음 >`}</span>
            </div>


            <div className="container-wrap" style={{ marginTop: '35px' }}>
              {
                arrProgram?.map((item: any, idx) => (
                  <div key={idx} className={'itembox03 b-width-25'} onClick={() => {
                    setSelectProgram(item);
                    setIsDpModal(true); }}>
                    <span className="itembox03-title01">{item.pro_m_nm}</span>
                    <span className="itembox03-title02">동영상 {item.in_array.length}개</span>
                  </div>
                ))
              }
            </div>
          </>
        )
      }

      {
        isDpModal && (
          <ModalSBDayProgram ModalCb={ModalCb} isModalOpen={isDpModal} history={history} selectProgram={selectProgram}/>
        )
      }
    </div>
  )

};

export default SBDayProgramEx;