let isSideMenu = 'n'; // n , l , r

export function SideLeftMenu() {
  const sideLeftBox = document.querySelector('.sideLeftBox');
  const sideRightBox = document.querySelector('.sideRightBox');
  const appContainer = document.querySelector('.appContainer');
  const NavTopBar = document.querySelector('.NavTopBar');

  if (isSideMenu !== 'l') {
    sideLeftBox?.setAttribute('class', 'sideLeftBox sideLeftBox-open');
    appContainer?.setAttribute('class', 'appContainer appContainer-sideLeftOpen');
    NavTopBar?.setAttribute('class', 'NavTopBar b-blur50 NavTopBar-sideLeftOpen');
    sideRightBox?.classList.remove('sideRightBox-open');
    appContainer?.classList.remove('appContainer-sideRightOpen');
  } else {
    sideLeftBox?.classList.remove('sideLeftBox-open');
    sideRightBox?.classList.remove('sideRightBox-open');
    appContainer?.classList.remove('appContainer-sideLeftOpen');
    appContainer?.classList.remove('appContainer-sideRightOpen');
    NavTopBar?.classList.remove('NavTopBar-sideLeftOpen');
  };
  isSideMenu = isSideMenu !== 'l' ? 'l' : 'n';
};


export function SideRightMenu() {
  const sideLeftBox = document.querySelector('.sideLeftBox');
  const sideRightBox = document.querySelector('.sideRightBox');
  const appContainer = document.querySelector('.appContainer');
  const NavTopBar = document.querySelector('.NavTopBar');

  if (isSideMenu !== 'r') {
    sideRightBox?.setAttribute('class', 'sideRightBox sideRightBox-open');
    appContainer?.setAttribute('class', 'appContainer appContainer-sideRightOpen');
    NavTopBar?.setAttribute('class', 'NavTopBar b-blur50 NavTopBar-sideLeftOpen');
    sideLeftBox?.classList.remove('sideLeftBox-open');
    appContainer?.classList.remove('appContainer-sideLeftOpen');
  } else {
    sideLeftBox?.classList.remove('sideLeftBox-open');
    sideRightBox?.classList.remove('sideRightBox-open');
    appContainer?.classList.remove('appContainer-sideLeftOpen');
    appContainer?.classList.remove('appContainer-sideRightOpen');
    NavTopBar?.classList.remove('NavTopBar-sideLeftOpen');
  };
  isSideMenu = isSideMenu !== 'r' ? 'r' : 'n';
};


export function ClassAdd(getHTMLparam: any, getQuery: string, getAtt: string) {
  const selItem = getHTMLparam.querySelector(getQuery);
  selItem?.setAttribute('class', getAtt);
};


export function ClassDel(getHTMLparam: any, getQuery: string, getRemoveName: string) {
  const selItem = getHTMLparam.querySelector(getQuery);
  selItem?.classList.remove(getRemoveName);
};
