import { useCallback, useState } from 'react';
import { Modal } from 'antd';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import * as MyUtil from "../constants/MyUtil";
import * as SG from '../constants/Signature';
import * as ServerApi from "../constants/ServerApi";
import imgDefault from "../img/bg_gym.jpg";
import '../css/CssOverride.css';
const isMobile = MyUtil.isMobile;


const ModalVideoSelect = ({ ModalCb, isModalOpen }: SG.ModalProps) => {
  const modalWidth = isMobile ? 'calc(100% - 40px)' : '550px';
  const [loading, setLoading] = useState<boolean>(false);
  const [contents, setContents] = useState('');
  const [targetType, setTargetType] = useState({ cd: '', nm: '전체' });
  const [arrCate, setArrCate] = useState<null | []>([]);
  const [arrVideItem, setArrVideItem] = useState<null | []>([]);



  const m_mng_mv_category_query = useQuery([arrCate], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_mv_category({}); }, {
    retry: 1, enabled: arrCate === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv_category', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv_category', result);
      if (isOk) {
        setArrCate(data.array);
      } else {
        setArrCate(null);
        MyUtil._alertMsg('m_mng_mv_category', data);
      };
    },
  });


  const m_mng_mv_query = useQuery([arrVideItem, targetType], (ctx: QueryFunctionContext) => {
    return ServerApi.m_mng_mv({ mv_category_no: targetType.cd });
  }, {
    retry: 1, enabled: arrVideItem === null ? false : true,  // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_mv', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_mv', result);

      if (isOk) {
        setArrVideItem(data.array);
      } else {
        setArrVideItem(null);
        MyUtil._alertMsg('m_mng_mv', data);
      };
    },
  });





  const getDays = (getArrCate: any) => {
    const result = [];
    for (let i = 0; i < getArrCate.length; i++) {
      result.push(<option key={i} value={getArrCate[i].mv_category_no + ''}>{getArrCate[i].mv_category_nm + ''}</option>);
    };
    return result;
  };



  return (
    <Modal
      width={modalWidth}
      visible={isModalOpen}
      title="영상 추가"
      onOk={() => { ModalCb(false, {}); }}
      onCancel={() => { ModalCb(false, {}); }}
      footer={[]}
    >
      <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}>
        <div className="modalIpWrap" style={{ marginTop: '12px', paddingBottom: '15px' }}>
          <span className="modalIpTitle">카테고리</span>
          <select name="" id="" className="modalIp solidSelect" onChange={({ target }: any) => { setTargetType({ cd: target.value, nm: target.textContent }); }}>
            <option value="">전체</option>
            {getDays(arrCate)}
          </select>
        </div>


        <div className="container-wrap" style={{ paddingLeft: '0px', paddingRight: '0px', maxHeight: '300px', marginTop: '10px', overflowY: 'auto' }}>
          {
            !MyUtil._isNull(arrVideItem) && arrVideItem?.map((item: any, idx) => (
              <div key={idx} className={'itemBoxModal'} style={{ backgroundColor: '#ffffff', position: 'relative', overflow: 'hidden' }} onClick={() => {
                let selectVideo = { ...item, ...{ id: parseInt(item.mv_no) } };
                ModalCb(true, selectVideo);
              }}>
                <img style={{ width: '100%', height: '160px', objectFit: 'cover', borderRadius: '5px' }} src={MyUtil._isNull(item.mv_rep_img) ? imgDefault : item.mv_rep_img} alt="logo image" />

                <div style={{ width: '100%', height: '32px', position: 'absolute', backgroundColor: 'rgba(0, 0, 0, 0.40)', bottom: '0px', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                  <span style={{ fontSize: '0.8em', color: '#ffffff', flex: 1, paddingLeft: '10px' }}>{item.mv_nm}</span>
                  {/* <span className="itemBoxModal-bottomBtnWrap-btnText" style={{ marginRight: '10px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }}
                    onClick={() => {
                    }}>{'삭제'}</span> */}
                </div>
              </div>
            ))
          }
        </div>


        {/* <div className="modalSaveBtn" onClick={() => { }}>
          <span className="loginBtnTxt">추 가</span>
        </div> */}
      </div>
    </Modal>
  );
}
export default ModalVideoSelect;