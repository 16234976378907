import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, QueryFunctionContext, useQuery } from 'react-query';
import { Modal } from 'antd';
import * as ServerApi from "../constants/ServerApi";
import * as MyUtil from "../constants/MyUtil";
import Loader from '../component/Loader';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../component/redux/rootReducer';
import allActions from "../component/redux/allActions";

const BranchPrograms = ({ location }: any) => {
  const dispatch = useDispatch();
  const { rxLoginInfo } = useSelector((state: RootState) => state.rxLoginInfo, (prev, next) => { return prev.rxLoginInfo === next.rxLoginInfo });
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const isMobile = MyUtil.isMobile;
  const [arrPackage, setArrPackage] = useState<null | []>([]);

  useEffect(() => { MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, false); }, []);


  const m_mng_pack_query = useQuery([arrPackage], (ctx: QueryFunctionContext) => { return ServerApi.m_mng_pack({ center_no: rxLoginInfo.center_no }); }, {
    retry: 1, enabled: arrPackage === null ? false : true, // staleTime: 2 * 1000,
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pack', error); },
    onSuccess: (result: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pack', result);

      if (isOk) {
        setArrPackage(data.array);
      } else {
        setArrPackage(null);
        MyUtil._alertMsg('m_mng_pack', data);
      };
    },
  });


  const { mutate: m_mng_pack_d } = useMutation(ServerApi.m_mng_pack_d, {
    retry: 1,
    onMutate: () => { setLoading(true); },
    onError: (error) => { setLoading(false); MyUtil._alertMsg('m_mng_pack_d', error); },
    onSuccess: (result: any, params: any) => {
      setLoading(false);
      const { isOk, data } = MyUtil._isResultSucces('m_mng_pack_d', result);

      if (isOk) {
        Modal.warning({ content: "정상적으로 삭제되었습니다!" });
        setArrPackage([]);   // ---- 리스트 재조회 
      } else {
        MyUtil._alertMsg('m_mng_pack_d', data);
      };
    }
  });





  return (
    <div className="container">
      {
        loading ? (<Loader />) : (
          <>
            <div className="container-wrap" style={{ marginBottom: '30px', alignItems: 'center', alignContent: 'center' }}>
              <span className="container-title">{!MyUtil._isNull(location?.state?.korName) ? location.state.korName : ''}</span>
              <span className="container-sub">{!MyUtil._isNull(location?.state?.subName) ? location.state.subName : ''}</span>

              <div className='b-empty-row'></div>

              {
                isMobile && <div style={{ width: '530px', height: '15px' }}></div>
              }

              <div className="titleBtn-Con" onClick={() => { history.push({ pathname: 'PackagesProgram', state: { korName: '패키지 추가', title: '', arrProVideos: [], subName: !MyUtil._isNull(location?.state?.subName) ? location.state.subName : '', pack_no: '' } }); }}>
                <span className='titleBtn-text'>패키지 추가</span>
              </div>
            </div>

            <div className="container-wrap">
              {
                !MyUtil._isNull(arrPackage) && arrPackage?.map((item: any, idx) => (
                  <div key={idx} className={isMobile ? 'itembox03 b-width-100' : 'itembox03 b-width-25'}>

                    <span className="itembox03-title01">{item.pack_nm}</span>

                    <div className='itembox03-bottomBtnWrap'>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '8px', color: '#ff392b', borderColor: '#ff392b', backgroundColor: '#ffffff' }} onClick={() => {
                        Modal.confirm({
                          okText: "확인", cancelText: "취소",
                          title: '패키지 삭제',
                          content: '등록된 패키지를 삭제할까요?',
                          onOk() { m_mng_pack_d({ pack_no: item.pack_no }); },
                          onCancel() { },
                        });
                      }}>{'삭제'}</span>
                      <span className="itembox01-bottomBtnWrap-btnText" style={{ marginRight: '0px', color: '#0fb500', borderColor: '#0fb500', backgroundColor: '#ffffff' }} onClick={() => { history.push({ pathname: 'PackagesProgram', state: { korName: '패키지 수정', arrProVideos: item.array, title: !MyUtil._isNull(item?.pack_nm) ? item.pack_nm : '', pack_no: item.pack_no } }); }}>{'패키지수정'}</span>
                    </div>
                  </div>
                ))
              }
            </div>
          </>
        )
      }





    </div>
  )

};

export default BranchPrograms;
