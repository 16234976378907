import { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Animate } from "react-simple-animate";
import { Modal } from 'antd';
import '../css/BarabomTest.css';

const BarabomTest = ({ location }: any) => {
  const isMobile = (window.innerWidth < 570);
  const [qusIndex, setQusIndex] = useState(0);
  const [qusMotionPlay, setQusMotionPlay] = useState(false);
  const history = useHistory();
  const tempData = [
    {
      qNo: 1,
      qus: '반려견에게 형제자매견이 필요하다고 생각하시나요?',
      ans: [{ ansNo: 1, txt: '당연히 필요하다 강아지도 외로움을 느끼니까 꼭 있어야한다. 무조건 필수' }, { ansNo: 2, txt: '혼자서도 잘 놀거야 없어도 돼.' }]
    },
    {
      qNo: 2,
      qus: '강아지를 얼마나 사랑하시나요?',
      ans: [{ ansNo: 1, txt: '매우 그렇다' }, { ansNo: 2, txt: '그렇다' }, { ansNo: 3, txt: '아니다' }, { ansNo: 4, txt: '매우 아니다' }]
    },
    {
      qNo: 3,
      qus: '파양하지 않을 자신이 있으신가요있으신가요있으신가요있으신가요있으신가요있으신가요있으신가요?',
      ans: [{ ansNo: 1, txt: '매우 그렇다' }, { ansNo: 2, txt: '그렇다' }, { ansNo: 3, txt: '아니다' }, { ansNo: 4, txt: '매우 아니다' }]
    },
    {
      qNo: 3,
      qus: '파양하지 않을 자신이 있으신가요?',
      ans: [{ ansNo: 1, txt: '매우 그렇다' }, { ansNo: 2, txt: '그렇다' }, { ansNo: 3, txt: '아니다' }, { ansNo: 4, txt: '매우 아니다' }]
    },
    {
      qNo: 3,
      qus: '파양하지 않을 자신이 있으신가요?',
      ans: [{ ansNo: 1, txt: '매우 그렇다' }, { ansNo: 2, txt: '그렇다' }, { ansNo: 3, txt: '아니다' }, { ansNo: 4, txt: '매우 아니다' }]
    },
    {
      qNo: 3,
      qus: '파양하지 않을 자신이 있으신가요?',
      ans: [{ ansNo: 1, txt: '매우 그렇다' }, { ansNo: 2, txt: '그렇다' }, { ansNo: 3, txt: '아니다' }, { ansNo: 4, txt: '매우 아니다' }]
    }
  ];

  useEffect(() => {
  }, []);


  return (
    <div className="container">
      <div className="container-wrap" style={{ alignItems: 'center', justifyContent: 'center', width: isMobile ? '100%' : '480px' }}>

        <div className="bt-progressWrap">
          <div className="bt-progress" style={{ flex: (qusIndex + 1), backgroundColor: '#f48123' }}></div>
          <div className="bt-progress" style={{ flex: tempData.length - (qusIndex), backgroundColor: '#dddddd' }}></div>
        </div>

        <div className="row-wrap" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <div style={{ position: 'absolute' }}>
            <Animate
              play={qusMotionPlay}
              duration={0.2}
              start={{ transform: "translateX(0px)", opacity: "1" }}
              end={{ transform: "translateX(50px)", opacity: "0" }}
            >
              <div className="row-wrap" style={{ alignItems: 'baseline', marginTop: '55px' }}>
                <span className="bt-question-title">Q. {qusIndex + 1}</span>
                <span className="bt-question-sub" style={{ marginLeft: '7px', marginRight: '7px' }}>/</span>
                <span className="bt-question-sub">{tempData.length}</span>
              </div>

              <div className="row-wrap" style={{ alignItems: 'center', marginTop: '26px', marginBottom: '30px' }}>
                <span className="bt-question-con">{tempData[qusIndex].qus}</span>
              </div>

              <div className="row-wrap">
                {
                  tempData[qusIndex].ans.map((ansItem, ansIdx) => (
                    <div key={ansIdx} className={'bt-answerBox'} onClick={() => {
                      if (qusMotionPlay) return;

                      if (qusIndex + 1 < tempData.length) {
                        setQusMotionPlay(true);
                        setTimeout(() => {
                          setQusIndex(prev => (prev + 1));
                          setQusMotionPlay(false);
                        }, 500);
                      } else {
                        Modal.warning({ content: '마지막 질문 입니다.'});
                      };
                    }}>
                      <span className="bt-answerText">{ansItem.txt}</span>
                    </div>
                  ))
                }
              </div>
            </Animate>
          </div>
        </div>
      </div>

    </div>
  )

};

export default BarabomTest;
